<template>
  <DraggableDiv class="c-popup">
    <template #header="{ start }">
      <!-- header -->
      <v-toolbar class="c-popup-toolbar" dense>
        <div>
          <v-icon
            class="c-dragger"
            @mousedown.native="start"
          >
            mdi-drag
          </v-icon>
        </div>
        <v-toolbar-title>{{ $t('model.info') }}</v-toolbar-title>
        <v-btn icon @click="$emit('update:show', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <!-- body -->
    <template #default>
      <v-card class="c-card">
        <v-card-text class="text-left">
          <slot></slot>
        </v-card-text>
      </v-card>
    </template>
  </DraggableDiv>
</template>

<script>
import DraggableDiv from '@/components/base/DraggableDiv'

export default {
  name: 'SceneInfo',

  components: {
    DraggableDiv
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {}
  }
}
</script>

<style lang="css" scoped>
.c-popup {
  width: 250px;
  max-width: 100%;
}
.c-popup-toolbar {
  height: 28px !important;
  background-color: var(--v-primary-base) !important;
}

.c-dragger:hover {
  cursor: grab;
}
.c-dragger:active {
  cursor: grabbing;
}

.c-card {
  height: 100%;
}
</style>

<style lang="css">
.c-popup-toolbar .v-toolbar__content {
  justify-content: space-between;
  height: 28px !important;
  padding-left: 0px;
  padding-right: 0px; /* button has 12px of right padding */
}
</style>

export default {
  data: function () {
    return {
      isFirstPage: false
    }
  },

  beforeRouteEnter: function (_to, from, next) {
    next((vm) => (vm.isFirstPage = from.name == null || from.name === 'login'))
  },

  methods: {
    /*
     * There are three "close" scenarios:
     * 1)If arrived via a (unauthenticated) share link -> Exit the app.
     * 2)If arrived via a bookmark -> Return to "home".
     * 3)If arrived via navigation -> Return to previous "page".
     */
    exitMixin_close() {
      const isProtectedLink = this.$route.meta?.requiresAuthentication
      isProtectedLink
        ? this.isFirstPage
          ? this.$router.push({ name: 'home' })
          : this.$router.back()
        : this.exitMixin_exit()
    },

    exitMixin_exit() {
      // close the window (but first, ensure the app owns the window)
      const url = `${window.location.origin}/splash?notice=exit`
      const w = window.open(url, '_self')
      w.close()
      // return false if the window didn't close
      return false
    }
  }
}

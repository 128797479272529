<template>
  <div class="c-carousel">
    <!-- slides -->
    <v-carousel
      v-model="currentSlide"
      class="c-slides"
      :continuous="false"
      height="100%"
      hide-delimiter-background
      :show-arrows="!isReallyMobile"
      :show-arrows-on-hover="!isReallyMobile"
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" class="c-slide">
        <v-sheet color="background" height="100%" tile>
          <v-row
            class="c-slide-wrapper fill-height"
            align="center"
            justify="center"
            @dblclick="toggleThumbs"
          >
            <slot name="slide" :src="slide" />
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <!-- thumbs -->
    <v-slide-group
      v-show="hasThumbs"
      v-model="currentSlide"
      class="c-thumbs d-flex justify-center"
      :class="{ 'c-thumbs--visible': showThumbs }"
      active-class="c-active-thumb"
      show-arrows
    >
      <v-slide-item
        v-for="(thumb, i) in thumbs"
        :key="i"
        v-slot="{ active: isSelected, toggle }"
        class="d-flex"
      >
        <div
          class="c-thumb-wrapper mx-2"
          :class="isSelected ? 'c-active-thumb' : 'c-inactive-thumb'"
          @click="toggle"
        >
          <slot name="thumb" :src="thumb" />
        </div>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'SlideCarousel',

  components: {},

  mixins: [mobileMixin],

  props: {
    slides: {
      type: Array,
      required: true
    },

    thumbs: {
      type: Array,
      required: false,
      default: () => []
    },

    landscape: {
      type: Boolean,
      required: false,
      default: true
    },

    width: {
      type: Number,
      required: false,
      default: 1024
    },

    height: {
      type: Number,
      required: false,
      default: 768
    }
  },

  data: function () {
    return {
      currentSlide: 0,
      showThumbs: false,
      onResizeDebounced: null,
      isLandscape: this.landscape
    }
  },

  computed: {
    hasMultipleSlides() {
      return this.slides.length > 1
    },

    hasThumbs() {
      return this.thumbs.length > 1
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    manualSync() {
      return this.hasThumbs && this.slides.length !== this.thumbs.length
    },

    thumbIndex() {
      const thumbNames = this.thumbs.map((thumb) => thumb.split('/').pop().split('.').shift())
      const slideNames = this.slides.map((slide) => slide.split('/').pop().split('.').shift())
      const indexArray = thumbNames.map((thumb) => slideNames.indexOf(thumb))
      return indexArray
    },

    thumbsPerView() {
      const aspectRatio = this.isLandscape ? 4 / 3 : 3 / 4
      const thumbHeight = this.swiperHeight * 0.2
      const thumbWidth = thumbHeight * aspectRatio
      const maxThumbs = thumbWidth
        ? Math.floor(this.swiperWidth / (thumbWidth + this.thumbOptions.spaceBetween))
        : 1
      return Math.min(maxThumbs, this.thumbs.length)
    }
  },

  created: function () {
    this.onResizeDebounced = this.debounce(this.onResize, 50, false)
  },

  mounted: function () {
    window.addEventListener('resize', this.onResizeDebounced)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResizeDebounced)
  },

  methods: {
    debounce(func, wait, immediate = false) {
      // 'private' variable for instance
      // The returned function will be able to reference this due to closure.
      // Each call to the returned function will share this common timer.
      var timeout

      // Calling debounce returns a new anonymous function
      return function () {
        // reference the context and args for the setTimeout function
        var context = this
        var args = arguments

        // Should the function be called now? If immediate is true
        //   and not already in a timeout then the answer is: Yes
        var callNow = immediate && !timeout

        // This is the basic debounce behaviour where you can call this
        //   function several times, but it will only execute once
        //   [before or after imposing a delay].
        //   Each time the returned function is called, the timer starts over.
        clearTimeout(timeout)

        // Set the new timeout
        timeout = setTimeout(function () {
          // Inside the timeout function, clear the timeout variable
          // which will let the next execution run when in 'immediate' mode
          timeout = null

          // Check if the function already ran with the immediate flag
          if (!immediate) {
            // Call the original function with apply
            // apply lets you define the 'this' object as well as the arguments
            //    (both captured before setTimeout)
            func.apply(context, args)
          }
        }, wait)

        // Immediate mode and no wait timer? Execute the function..
        if (callNow) func.apply(context, args)
      }
    },

    onResize() {},

    toggleThumbs(_swiper, _event) {
      if (this.thumbs.length > 1) {
        this.showThumbs = !this.showThumbs
      }
    },

    onTap(event) {
      if (this.tapTimer) {
        clearTimeout(this.tapTimer)
        this.tapTimer = null
        event.preventDefault()
        this.toggleThumbs()
      } else {
        this.tapTimer = setTimeout(() => {
          this.tapTimer = null
        }, 300)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-carousel {
  position: relative;
  height: 100%;
  width: 100%;
}
.c-carousel :deep(.v-carousel__controls__item) {
  background-color: rgba(0, 0, 0, 0.2);
}
.c-carousel :deep(.v-window .v-carousel__controls) {
  display: none;
}
.c-carousel :deep(.v-window:hover .v-carousel__controls) {
  display: flex;
}
.c-carousel :deep(.v-slide-group__content) {
  justify-content: center;
}
.c-slide-wrapper {
  margin: 0;
}

/* thumb styles */
.c-thumbs {
  background-color: var(--v-background-base);
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.5s ease;
  z-index: 999;
}
.c-thumbs.c-thumbs--visible {
  display: flex;
  justify-content: center;
  height: max(20%, 120px);
  height: 72px;
}
.c-thumb-wrapper {
  width: 64px;
  height: 64px;
  margin: auto;
  object-fit: contain;
  cursor: pointer;
}
.c-active-thumb {
  opacity: 1;
  outline: solid 2px;
  outline-color: var(--v-accent-base);
}
.c-inactive-thumb {
  opacity: 0.5;
}
</style>

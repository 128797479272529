<template>
  <div class="c-player-view">
    <CircularProgress
      v-if="isLoading"
      class="c-player-progress"
      :message="$t('message.fetchingContent')"
    />
    <ContentPlayer
      v-if="!isLoading && isFound"
      :canvas="canvas"
      :fullscreen="fullscreen"
      :item="item"
      @context="$emit('context', $event)"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import ContentPlayer from '@/components/content/ContentPlayer'
import metaMixin from '@/mixins/metaMixin'
import shareMixin from '@/mixins/shareMixin'

import ContentService from '@/services/contentService'
import { mapActions, mapGetters } from 'vuex'

class ParameterError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ParameterError'
  }
}

export default {
  name: 'PlayerView',

  components: {
    CircularProgress,
    ContentPlayer
  },

  mixins: [metaMixin, shareMixin],

  beforeRouteEnter: function (_to, from, next) {
    next((vm) => (vm.from = from))
  },

  beforeRouteUpdate: async function (to, from, next) {
    console.warn('[PlayerView]: beforeRouteUpdate!')
    this.from = from
    const id = this.shareMixin_extractId(to.params.id)
    this.resetData()
    await this.fetchAll(id)
    next()
  },

  props: {
    id: {
      type: String,
      required: true
    },

    canvas: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // content
      contentService: null,
      context: null,
      isLoading: true,
      isFound: false,
      item: null,

      // scrolling
      isFullscreen: false,
      prevScrollPosition: 0,

      // navigation
      from: ''
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    locale() {
      return this.$store.state.i18nStore.locale
    },

    orgName() {
      return this.$store.state.orgStore.orgName
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    metaTags() {
      const title = this.item?.title || this.$t('ui.loading')
      const description = this.item?.abstract || ''
      const thumbnail = this.item?.thumbnail || ''
      return {
        loaded: !this.isLoading,
        docTitle: title + ' | ' + this.orgName,
        meta: [
          // OpenGraph
          { property: 'og:title', content: title },
          { property: 'og:image', content: thumbnail },
          { property: 'og:description', content: description },
          { property: 'og:site_name', content: this.orgName },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: window.location.href },

          // Google / Schema.org
          { itemprop: 'name', content: title },
          { itemprop: 'image', content: thumbnail },
          { itemprop: 'description', content: description }
        ]
      }
    }
  },

  mounted: function () {
    if (this.from) {
      this.$emit('from', this.from)
    }
  },

  created: async function () {
    this.contentService = new ContentService()
    const id = this.shareMixin_extractId(this.id)
    this.resetData()
    await this.fetchAll(id)
  },

  methods: {
    ...mapActions('adStore', ['clearAds', 'fetchAds']),

    async fetchAll(id) {
      try {
        // fetch item
        this.item = await this.fetchItem(id)
        this.$emit('item', this.item)

        // fetch ads
        this.clearAds()
        const itemKeywords = this.item.adWords || []
        const topicKeywords = this.item.topicKeywords || []
        const portalKeywords = [this.portalKey]
        const keywords = [...itemKeywords, ...topicKeywords, ...portalKeywords]
        this.fetchAds({
          locale: this.locale,
          keywords
        })
      } catch (error) {
        console.error('[PlayerView]:', error)
        await this.showAlert(error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchItem(id) {
      try {
        this.isFound = false
        if (id) {
          const item = await this.contentService.fetchContentItem(id)
          this.isFound = true
          return item
        } else {
          throw new ParameterError('Error: invalid id in url.')
        }
      } catch (error) {
        console.error('[PlayerView]:', error)
        throw error
      }
    },

    resetData() {
      // content
      this.context = null
      this.isLoading = true
      this.isFound = false
      this.item = null

      // scrolling
      this.prevScrollPosition = 0
    },

    async showAlert(error) {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        footer: error,
        confirmButtonText: this.$t(`ui.close`)
      })
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-player-view {
  background-color: var(--v-background-base);
  min-height: 100%;
  height: 100%;
}
</style>

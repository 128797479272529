import Vue from 'vue'
import Vuex from 'vuex'
import { libraryCollectionKey as LIBRARY_COLLECTION_KEY } from '@/config/appConfig'
import ContentService from '@/services/contentService'

const contentService = new ContentService()

Vue.use(Vuex)

const initialState = {
  // persisted state
  collections: [],
  collectionItems: {},
  headers: [],
  taxonomyTitles: {},
  // transient state
  loading: {},
  promises: {},
  // storage
  storage: 'indexDB'
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    activePortal(_state, _getters, rootState) {
      return rootState.portalStore.activePortal
    },

    getCollectionSpec(state) {
      return (collectionKey) =>
        state.collections.find((collection) => collection.key === collectionKey)
    },

    getCollectionTitle(getters) {
      return (collectionKey) =>
        collectionKey === LIBRARY_COLLECTION_KEY
          ? Vue.prototype.$t('menu.library.title')
          : getters.getCollectionSpec(collectionKey).title ||
            Vue.prototype.$t('menu.collection.title')
    },

    getCollectionItems(state) {
      return (collectionKey) => state.collectionItems[collectionKey] || []
    },

    getItemById(state) {
      return (id, collectionName) => {
        return state.collectionItems?.[collectionName].find((item) => item.id === id)
      }
    },

    getItemsByAuthor(state) {
      return (authorId) =>
        state.collectionItems[LIBRARY_COLLECTION_KEY]?.find((item) => item.authorId === authorId)
    },

    getItemsByCollection(state) {
      return (collectionName) =>
        collectionName in state.collectionItems ? state.collectionItems[collectionName] : []
    },

    isCollectionLoading(state) {
      return (key) => !!state.loading[key]
    },

    isLibraryLoading(state) {
      return () => !!state.loading[LIBRARY_COLLECTION_KEY]
    },

    libraryItems(state) {
      return state.collectionItems[LIBRARY_COLLECTION_KEY] || []
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // FIXME: use "deepmerge" instead
      state.collections = [...newState.collections]

      for (const collectionName in newState.collectionItems) {
        state.collectionItems[collectionName] = [...newState.collectionItems[collectionName]]
      }

      state.headers = [...newState.headers]

      state.taxonomyTitles = { ...newState.taxonomyTitles }

      // note: promises, loading are not refreshed with session state
    },

    /* specific mutations */

    setCollectionItems(state, { key, items }) {
      const newItems = { [key]: items }
      state.collectionItems = {
        ...state.collectionItems,
        ...newItems
      }
    },

    setCollectionItemsSet(state, collectionItemsSet) {
      state.collectionItems = {
        ...state.collectionItems,
        ...collectionItemsSet
      }
    },

    setCollections(state, collections) {
      state.collections = collections
    },

    setHeaders(state, headers) {
      state.headers = headers
    },

    setLoading(state, { key, loading, promise = null }) {
      // update promise
      if (key in state.loading) {
        state.loading[key] = loading
        state.promises[key] = promise
      } else {
        state.loading = {
          ...state.loading,
          [key]: loading
        }
        state.promises = {
          ...state.promises,
          [key]: promise
        }
      }
    },

    setTaxonomyTitles(state, taxonomyTitles) {
      state.taxonomyTitles = taxonomyTitles
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* fetch actions */

    async fetchLibrary({ dispatch }, { resync = false }) {
      await dispatch('fetchCollectionItems', {
        key: LIBRARY_COLLECTION_KEY,
        resync
      })
    },

    async fetchCollectionItems({ state, commit, getters }, { key, resync = false }) {
      // set headers
      if (state.headers.length < 1) {
        const headers = contentService.getContentHeaders()
        if (headers.length > 0) commit('setHeaders', headers)
      }

      // get items
      if (resync || !(key in state.collectionItems)) {
        // get items from CMS (full library or optionally constrained by portal and/or collection)
        console.debug(`[contentStore]: Fetching collection '${key}' from CMS.`)
        const activePortal = getters.activePortal
        console.debug(`[contentStore]: Active portal=`, activePortal)
        const filter = activePortal.contentFilter

        try {
          // handle case where multiple components fetch content
          let promise
          if (state.loading[key]) {
            console.debug(`[contentStore]: Using existing promise for key '${key}'.`)
            promise = state.promises[key]
          } else {
            console.debug(`[contentStore]: Creating new promise via fetch for key '${key}'.`)
            console.warn(
              `[contentStore]: Filter for portal ${getters.activePortal.key} is '${filter}'.`
            )
            promise = contentService.fetchContent({ filter, collectionKey: key })
            commit('setLoading', { key, loading: true, promise })
          }
          const items = await promise
          commit('setCollectionItems', { key, items })
        } catch (error) {
          console.warn('[contentStore]: Using cached collection items.', error)
        } finally {
          commit('setLoading', { key, loading: false })
        }
      } else {
        // get items from cache
        const count = state.collectionItems[key].length
        console.debug(`[contentStore]: Fetching collection '${key}' from cache (${count}).`)
      }
      return state.collectionItems[key]
    },

    async fetchCollectionItemsSet({ state, commit, getters }, { keys, resync = false }) {
      // set headers
      if (state.headers.length < 1) {
        const headers = contentService.getContentHeaders()
        if (headers.length > 0) commit('setHeaders', headers)
      }

      // get items
      const filter = getters.activePortal?.contentFilter
      const collectionItemsSet = {}

      for (const key of keys) {
        if (resync || !(key in state.collectionItems) || state.collectionItems[key].length < 1) {
          // get items from CMS (full library or optionally constrained by portal and/or collection)
          console.debug(`[contentStore]: Fetching collection '${key}' from CMS.`)

          try {
            // handle case where multiple components fetch content
            let promise
            if (state.loading[key]) {
              console.debug(`[contentStore]: Using existing promise for key '${key}'.`)
              promise = state.promises[key]
            } else {
              console.debug(`[contentStore]: Creating new promise via fetch for key '${key}'.`)
              promise = contentService.fetchContent({ filter, collectionKey: key })
              commit('setLoading', { key, loading: true, promise })
            }
            const items = await promise
            collectionItemsSet[key] = items
          } catch (error) {
            console.warn('[contentStore]: Using cached collection items.', error)
          } finally {
            commit('setLoading', { key, loading: false })
          }
        } else {
          // get items from cache
          const count = state.collectionItems[key].length
          console.debug(`[contentStore]: Fetching collection '${key}' from cache (${count}).`)
        }
      }
      commit('setCollectionItemsSet', collectionItemsSet)
      return collectionItemsSet
    },

    /* specific actions */

    addCollection({ state, commit, dispatch }, { collection }) {
      // check if collection (and hence its items) is already in the state
      if (state.collections.find((c) => c.key === collection.key)) return

      // add the collection to the state
      const collections = [...state.collections, collection]
      commit('setCollections', collections)

      // add the collection items to the state
      dispatch('fetchCollectionItems', { key: collection.key, resync: true })
    },

    updateCollections({ commit }, { collections }) {
      commit('setCollections', collections || [])
    },

    updateCollectionItems({ commit }, { key, items }) {
      commit('setCollectionItems', { key, items })
    },

    updateTaxonomyTitles({ commit }, { taxonomyTitles }) {
      commit('setTaxonomyTitles', taxonomyTitles)
    }
  }
}

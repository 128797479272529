<template>
  <div class="c-player-layout" :class="{'c-fullscreen': isFullscreen }">
    <PlayerBar
      v-model="showDetails"
      class="c-player-bar"
      :class="{'c-hide': isFullscreen }"
      :item="item"
      @activate:canvas="isCanvasActivated = $event"
      @close="onClose"
      @fullscreen="isFullscreen = $event"
    />

    <PlayerDrawer
      class="c-player-drawer"
      :context="context"
      :item="item"
      :open="showDetails"
    />

    <v-main
      class="c-player-main"
      :class="{'c-fullscreen': isFullscreen }"
    >
      <transition name="slide">
        <router-view
          :key="$route.path"
          :fullscreen="isFullscreen"
          :canvas="isCanvasActivated"
          @context="context = $event"
          @item="item = $event"
          @from="from = $event"
        />
      </transition>
    </v-main>
  </div>
</template>

<script>
import PlayerBar from '@/components/player/PlayerBar'
import PlayerDrawer from '@/components/player/PlayerDrawer'
import exitMixin from '@/mixins/exitMixin.js'

export default {
  name: 'PlayerLayout',

  components: {
    PlayerBar,
    PlayerDrawer
  },

  mixins: [exitMixin],

  data: function () {
    return {
      // content
      item: null,
      showDetails: false, // null means initially open on mobile, closed on desktop
      isCanvasActivated: false,
      context: null,

      // scrolling
      isFullscreen: false,
      prevScrollPosition: 0
    }
  },

  computed: {
    hasHistory() {
      return window.history.length > 2
    },

    isDrawerOpen() {
      return this.showDetails === null
        ? this.$vuetify.breakpoint.mobile
          ? false
          : this.item.details?.showDetails
        : !!this.showDetails
    }
  },

  watch: {
    isMobile: {
      immediate: true,
      handler: function () {
        // because we are using this handler immediately we need to wait for data changes using nextTick
        this.$nextTick(() => {
          const root = document.documentElement
          this.isMobile
            ? root.style.setProperty('--c-player-bar-height', '48px')
            : root.style.setProperty('--c-player-bar-height', '48px')
        })
      }
    }
  },

  mounted: function () {
    window.addEventListener('backbutton', this.onClose)
    window.addEventListener('unload', this.onRefresh)
  },

  beforeDestroy: function () {
    window.removeEventListener('backbutton', this.onClose)
    window.removeEventListener('unload', this.onRefresh)
  },

  methods: {
    /* event handlers */

    onClose() {
      /*
       * There are three events that initiate closing a player:
       * 1)The Player emits an "error" event.
       * 2)The user selects the "close" from the PlayerBar
       * 3)The user hits the browser "back" button.
       *
       */
      console.debug('[Player]: Closing the player.')
      this.exitMixin_close()
    },

    onError() {
      console.debug('[Player]: Closing the player due to an error.')
      this.onClose()
    },

    onRefresh(event) {
      event.preventDefault()
      console.debug('[Player]: refreshing the page.')
      // this.$router.go(0)
      return // don't let Safari show the "are you sure?" dialog box
    }
  }
}
</script>

<style lang="css" scoped>
.c-player-layout {
  height: 100%;
  /* DELETE ME
  min-height: 100vh;
  */
}
.c-player-layout.c-fullscreen {
  --c-player-bar-height: 0px;
}

.c-player-main {
  background-color: var(--v-background-base);
  height: 100%;
  padding-bottom: 0 !important;
}

/* handle minmax */
.c-player-bar.c-hide {
  visibility: hidden;
}
.c-player-main.c-fullscreen {
  padding-top: 0 !important;
  min-height: 100vh;
}
</style>

<template>
  <v-clamp :max-lines="maxLines" autoresize>
    <!-- eslint-disable-next-line vue/no-v-html -->
    {{ text }}
    <template #after="{ toggle, clamped, expanded }">
      <v-btn v-if="clamped" class="toggle btn btn-sm" small text @click.stop="toggle">
        {{ $t('ui.more') }}
      </v-btn>
      <v-btn v-if="expanded" class="toggle btn btn-sm" small text @click.stop="toggle">
        {{ $t('ui.less') }}
      </v-btn>
    </template>
  </v-clamp>
</template>

<script>
import VClamp from 'vue-clamp'

export default {
  name: 'ClampedText',

  components: {
    'v-clamp': VClamp
  },

  props: {
    maxLines: {
      type: Number,
      required: false,
      default: 3
    },

    text: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      isClamped: true
    }
  }
}
</script>

<style lang="css" scoped>
.c-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>


/* model control event messages */
export const EventNames = {
  // app controls
  EXIT: 'Exit',
  LOAD_SCENE: 'LoadScene',
  MAKE_FULLSCREEN: 'MakeFullscreen',
  RESET: 'Reset',

  // model controls
  MODE_MOVE: 'Move',
  MODE_ROTATE: 'Rotate',
  MODE_SCALE: 'Scale',
  REVOLVE: 'Spin',
  SHOW_ANNOTATIONS: 'Annotate',
  TOGGLE_ANIMATION: 'Animate',
  ZOOM_IN: 'ZoomIn',
  ZOOM_OUT: 'ZoomOut'
}

import CryptoService from '@/services/cryptoService'

const cryptoService = new CryptoService()
const SHARE_PREFIX = 'share-'

export default {
  methods: {
    shareMixin_prepId(id) {
      return SHARE_PREFIX + cryptoService.encrypt(id)
    },

    shareMixin_extractId(id) {
      return id.startsWith(SHARE_PREFIX) ? cryptoService.decrypt(id.replace(SHARE_PREFIX, '')) : id
    },

    async shareMixin_shareItem(item) {
      return this.shareMixin_shareEntity({
        id: item.id,
        abstract: item.abstract,
        title: item.title,
        routeName: 'player'
      })
    },

    async shareMixin_shareEntity({ id, abstract, title, routeName }) {
      const shareableId = this.shareMixin_prepId(id)
      if (typeof window !== 'undefined' && window.navigator.share) {
        try {
          await navigator.share({
            title: title,
            url: `${window.location.origin}/${routeName}/${shareableId}`,
            text: abstract
          })
        } catch (error) {
          console.error(error)
        }
        return false
      } else {
        return true
      }
    }
  }
}

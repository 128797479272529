import VueRouter from 'vue-router'
import router from '@/router'

const loginRoute = (to) => ({
  name: 'login',
  query: {
    params: to.query?.params,
    redirect: to.fullPath // includes path, query and hash
  }
})

const redirectRoute = (redirectURI) => {
  const url = new URL(redirectURI, window.location.origin)
  const queryParams = Object.fromEntries(url.searchParams)
  return {
    path: url.pathname,
    query: queryParams
  }
}

const errorRoute = (error) => ({
  name: 'error',
  query: {
    code: error.name || 'SystemError',
    message: error.message
  }
})

// ensure authentication / authorization
const authGuard = async (to, from, next) => {
  try {
    // wait for store to be loaded
    // await router.app.$store.restored

    // is route protected?
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuthentication)
    console.debug('[router:authGuard]: Route requiresAuthentication=', requiresAuth)

    if (requiresAuth) {
      const authService = router.app.$auth
      let redirectURI = ''

      // wait for authentication service
      if (!authService.isReady()) {
        await authService.waitUntilReady()
      }

      // ensure initialization succeeded
      if (!authService.isOK()) {
        throw authService.getError()
      }

      // retrieve authentication / authorization status
      const isAuthenticated = authService.isAuthenticated()
      const isAuthorized = authService.isAuthorized()
      console.debug('[router:authGuard]: authenticated=', isAuthenticated)
      console.debug('[router:authGuard]: authorized=', isAuthorized)

      if (!isAuthenticated) {
        // check if fore a previous/new authenticated session
        if (from === VueRouter.START_LOCATION) {
          console.debug(
            '[router:authGuard]: Checking for redirect callback after initial app load.'
          )
          const state = await authService.handleRedirect()
          redirectURI = state?.redirectURI
        }

        // recheck the session
        console.debug('[router:authGuard]: Routing....')
        if (authService.isAuthenticated()) {
          console.debug('[router:authGuard]: redirectURI=', redirectURI)
          redirectURI ? next(redirectRoute(redirectURI)) : next()
        } else {
          next(loginRoute(to))
        }
      } else {
        // authorize the (already authenticated) user
        if (!isAuthorized) {
          console.debug('[router:authGuard]: Authorizing....')
          const userId = authService.getUserId()
          await authService.authorize(userId)
          // await store.userStore.syncUser({ userId })
        }
        next()
      }
    } else {
      // no authentication required, so proceed
      next()
    }
  } catch (error) {
    console.error('[router:authGuard]: An error occured.', error)
    next(errorRoute(error))
  }
}

// restrict routes to those configured for this tenant
const routeGuard = (to, from, next) => {
  // const isActive = router.app.$store.state.menuStore.menuRoutes.includes(to.name)
  const isActive = true
  isActive ? next() : next({ name: 'error404' })
}

// saves the scroll position of the "from" page
const scrollGuard = (to, from) => {
  const position = {
    x: window.pageXOffset,
    y: window.pageYOffset
  }
  console.debug(`[router:scrollGuard]: scroll position=(${position.x}, ${position.y})`)
  from.meta.scrollPosition = position
}

// called before navigating to the route
export const beforeEach = async (to, from, next) => {
  console.warn(`[router:beforeEach]: Routing to ${to.fullPath}...`)
  await authGuard(to, from, next)
}

// called before component loads, but after in-component guards run
export const beforeResolve = (to, from, next) => {
  routeGuard(to, from, next)
}

// called just before the "to" component DOM renders
export const afterEach = (to, from) => {
  if (to.name === 'iplayer') scrollGuard(to, from)
}

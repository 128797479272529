const initialState = {
  // persisted state
  posts: [],
  // transient state
  loading: false,
  promise: null,
  // storage
  storage: 'indexDB'
}

export default () => initialState

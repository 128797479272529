/* eslint-disable no-console */
import { register } from 'register-service-worker'
import { getFeature } from '@/config/featureConfig.js'

// self.importScripts('https://storage.googleapis.com/workbox-cdn/releases/4.3.1/workbox-sw.js')

// const isProduction = process.env.NODE_ENV === 'production'
const basePath = process.env.BASE_URL

const notifyUserAboutUpdate = (worker) => {
  if (window.confirm('New content! Do you want to reload the app?')) {
    worker.postMessage({ action: 'skipWaiting' })
  }
}

export default function () {
  if (getFeature('pwa')) {
    register(`${basePath}service-worker.js`, {
      registrationOptions: { scope: './' },

      ready(_registration) {
        console.warn(
          '[PWA]: App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        )
      },

      registered(registration) {
        console.warn('[PWA]: Service worker has been registered.')

        setInterval(() => registration.update(), 1000 * 60 * 60) // hourly checks
      },

      cached(_registration) {
        console.warn('[PWA]: Content has been cached for offline use.')
      },

      updatefound(_registration) {
        console.warn('[PWA]: New content is downloading.')
      },

      updated1(registration) {
        console.warn('[PWA]: New content is available.')
        notifyUserAboutUpdate(registration.waiting)
      },

      updated(registration) {
        console.warn('[PWA]: New content is available.')
        document.dispatchEvent(new CustomEvent('updateAvailable', { detail: registration }))
      },

      offline() {
        console.warn('[PWA]: No internet connection found. App is running in offline mode.')
      },

      error(error) {
        console.error('[PWA]: Error during service worker registration:', error)
      }
    })
  }
}

<template>
  <div class="c-content-player">
    <div
      ref="contentPlayer"
      class="c-content-player__viewport"
      :class="[{ 'c-fullscreen': isFullscreen }, isReallyMobile ? 'c-mobile' : 'c-desktop']"
    >
      <AdBlock
        v-if="showAds"
        ref="adBlock"
        v-model="showAds"
        class="c-content-player__ads pa-4"
        :class="isReallyMobile ? 'c-mobile' : 'c-desktop'"
        :max="1"
        :image="isReallyMobile"
        :stack="!isReallyMobile"
        :outline="false"
        :zone="isReallyMobile ? 'leaderboard' : 'side'"
        @height="onHeightChange($event)"
        @update:show="onHeightChange(0)"
      />

      <component
        :is="player"
        ref="component"
        class="c-content-player__component"
        :class="isReallyMobile ? 'c-mobile' : 'c-desktop'"
        :content="item"
        :fullscreen="isFullscreen"
        @context="$emit('context', $event)"
        @error:handled="$emit('error')"
        @error:loading="onError"
      />
    </div>
    <DrawCanvas
      class="c-content-player__canvas"
      :activated="isCanvasActivated"
      :type="item.mediaType"
      @capture="capture"
      @download="download"
    />
  </div>
</template>

<script>
import AdBlock from '@/components/ad/AdBlock'
import DrawCanvas from '@/components/draw/DrawCanvas'
import Players from '@/players/playerRegistry.json'
import mobileMixin from '@/mixins/mobileMixin.js'
import { toPng } from 'html-to-image'
import { playerBarHeight as PLAYER_BAR_HEIGHT } from '@/config/appConfig.js'

export default {
  name: 'ContentPlayer',

  components: {
    AdBlock,
    DrawCanvas
  },

  mixins: [mobileMixin],

  props: {
    item: {
      type: Object,
      required: true
    },

    canvas: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // canvas
      canvasHeight: 0,
      canvasWidth: 0,
      isCanvasActivated: false,
      wasCanvasActivated: false,
      offsetX: 0,
      offsetY: 0,

      // content
      context: {},
      showAds: false,

      // preview
      previewSrc: '',
      showPreview: false
    }
  },

  computed: {
    /* layout related */

    isFullscreen() {
      return this.fullscreen
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    player() {
      return this.getPlayer(this.item.mediaType)
    },

    /* ad related */

    isAdFree() {
      return (
        !this.item ||
        this.item.adFree === true ||
        this.item.mediaType === 'article' || // articles have their own ad layout
        this.item.contentTypeKeyword === 'detail-aid' ||
        this.item.contentTypeKeyword === 'learning-aid' ||
        this.item.contentTypeKeyword === 'cme'
      )
    },

    sideAds() {
      // note: accesses adStore.ads to retain reactivity
      const ads = this.$store.state.adStore.ads
      return ads.length > 0 ? this.getAds('side') : []
    },

    leaderboardAds() {
      // note: accesses adStore.ads to retain reactivity
      const ads = this.$store.state.adStore.ads
      return ads.length > 0 ? this.getAds('leaderboard') : []
    },

    hasSideAds() {
      return !this.isAdFree && this.sideAds.length > 0
    },

    hasLeaderboardAds() {
      return !this.isAdFree && this.leaderboardAds.length > 0
    },

    zone() {
      return this.isReallyMobile ? 'leaderboard' : 'side'
    }
  },

  watch: {
    canvas: {
      handler: function (newValue, _oldValue) {
        this.isCanvasActivated = newValue
      }
    },

    fullscreen: {
      immediate: false,
      handler: function (newValue, _oldValue) {
        const isFullscreen = newValue
        if (isFullscreen) {
          this.wasCanvasActivated = this.isCanvasActivated
          this.isCanvasActivated = false
        } else {
          this.isCanvasActivated = this.wasCanvasActivated
        }
      }
    }
  },

  mounted: function () {
    console.debug('[ContentPlayer]: params=', JSON.stringify(this.item.params || {}))
    this.isCanvasActivated = this.canvas
    this.showAds = !this.isAdFree
  },

  methods: {
    async onError() {
      await this.showAlert()
      this.$emit('error')
    },

    onHeightChange(adHeight) {
      if (this.isReallyMobile) {
        const componentHeight =
          adHeight < 0
            ? window.innerHeight - PLAYER_BAR_HEIGHT - (this.$refs.adBlock?.$el.clientHeight || 0)
            : window.innerHeight - PLAYER_BAR_HEIGHT - adHeight
        console.warn('[ContentPlayer]: ad height=', adHeight)
        console.warn('[ContentPlayer]: component height=', componentHeight)
        this.$refs.component.$el.style.height = componentHeight + 'px'
      }
    },

    getPlayer(mediaType) {
      const player = Players.find((player) => player.mediaType === mediaType)?.player || 'WebViewer'
      return () => import(/* webpackChunkName: "[request]" */ `@/players/${player}/index.js`)
    },

    async showAlert() {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        confirmButtonText: this.$t(`ui.close`)
      })
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    },

    async capture() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.createElement('video')

      /* capture screen */
      try {
        // start RTC video stream
        const captureStream = await navigator.mediaDevices.getDisplayMedia()
        video.srcObject = captureStream

        // capture an data image of the video frame
        context.drawImage(video, 0, 0, window.width, window.height)
        const dataURI = canvas.toDataURL('image/png')
        console.log('dataURI=', dataURI)

        // create a blob from the data image
        const dataBlob = await (await fetch(dataURI)).blob()
        console.log('blob=', dataBlob)

        // close the RTC video stream
        captureStream.getTracks().forEach((track) => track.stop())

        // paste the blob into the clipboard and download it
        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': dataBlob })])
        window.saveAs(dataBlob, 'lumenii.png')

        // render the img in a preview popup
        this.previewSrc = dataURI
        this.showPreview = true
      } catch (error) {
        console.error('[DrawCanvas]: Error capturing screen.', error)
      }
    },

    async download() {
      // generate a DOM image
      try {
        const dataURI = await toPng(this.$refs.contentPlayer)
        this.previewSrc = dataURI
      } catch (error) {
        console.error('[ContentPlayer]: Oops, something went wrong!', error)
      }

      // render the img in a preview popup
      this.showPreview = true

      // download image
      const link = document.createElement('a')
      link.download = 'lumenii.png'
      link.href = this.previewSrc
      link.click()
    }
  }
}
</script>

<style lang="css" scoped>
/* Layers:
 *  -1: Drawing Canvas (inactive)
 *   0: Player Viewport
 *   1: Drawing Canvas (active)
 *   2: Content Details Drawer
 *   3: Header Bar
 *   3: Controls
 */

.c-content-player {
  height: 100%;
}
.c-content-player__viewport {
  display: flex;
  width: 100%;
}
.c-content-player__viewport.c-desktop {
  flex-direction: row-reverse;
  justify-content: center;
  height: 100%;
}
.c-content-player__viewport.c-mobile {
  flex-direction: column;
  justify-content: center;
}

.c-content-player__component {
  flex: auto;
  background-color: var(--v-background-base);
  z-index: 0;
  height: 100%;
}

.c-content-player__ads {
  flex: auto;
  background-color: var(--v-background-base);
  height: 100%;
  width: 100%;
}

.c-content-player__ads.c-desktop {
  border-left: 1px solid var(--v-outline-base);
  max-width: 333px; /* 300 image + 2 * 16 padding + 1 border */
  min-width: 150px;
  height: 100%;
}
.c-content-player__ads.c-mobile {
}

@media only screen and (min-width: 600px) {
}
.c-content-player__viewport.c-fullscreen .c-content-player__ads {
  display: none;
}
.c-content-player__viewport.c-fullscreen .c-content-player__component {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: black;
}
</style>

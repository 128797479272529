<template>
  <v-expansion-panel class="c-section-panel" expand>
    <v-expansion-panel-header
      class="c-section-panel-header text-h6"
      color="secondary"
    >
      <div>
        <slot name="header">
          {{ title }} {{ count >= 0 ? `(${count})` : '' }}
        </slot>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="c-section-panel-content" color="sheet">

      <div class="c-toolbar">
        <slot name="toolbar" />
      </div>
      <v-progress-linear
        class=""
        :active="loading"
        color="red"
        :height="8"
        indeterminate
        striped
      />
      <div v-if="!loading && count === 0" class="c-text">
        <slot name="text" class="pa-4">
          {{ $t('ui.empty') }}
        </slot>
      </div>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'SectionPanel',

  components: {},

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    count: {
      type: Number,
      required: false,
      default: -1
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      layout: 'tree'
    }
  },

  computed: {},

  methods: {}
}
</script>

<style lang="css">
.c-section-panel-content .v-expansion-panel-content__wrap {
  height: 100%;
  padding: 0px;
}
</style>

<style lang="css" scoped>
.c-section-panel {
  height: 100%;
  margin-top: 0px !important;
}
.c-section-panel-header,
.c-section-panel-header.v-expansion-panel-header--active {
  padding: 0px 16px;
  min-height: 48px;
}
.c-text {
  padding: 16px;
}
</style>

export default {
  activePortal(_state, _getters, rootState) {
    return rootState.portalStore.activePortal
  },

  getPostsByAuthor(state) {
    return (authorId) => state.posts.find((post) => post.authorId === authorId)
  },

  getCommentsByPost(state) {
    return (postId) => {
      const postIndex = state.posts.findIndex((post) => post.id === postId)
      return state.posts[postIndex].comments
    }
  }
}

export default {
  /* Notes:
   *  1)Vue variables can't be prefixed with $ or _
   *  2)Computed ensures reactive translations occur
   */
  computed: {
    contentMixin_locale() {
      return this.$store.state.i18nStore.locale
    },

    contentMixin_defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    contentMixin_filters() {
      return [
        {
          field: 'sections', // field name in content
          label:
            this.$store.state.contentStore.taxonomyTitles?.section?.[this.contentMixin_locale] ||
            this.$t('content.section'), // better to use a translated value vs. a non-translated defaultLocale value!
          isMultiValued: true,
          requiresTranslation: true
        },
        {
          field: 'topics', // field name in content
          label:
            this.$store.state.contentStore.taxonomyTitles?.topic?.[this.contentMixin_locale] ||
            this.$t('content.topic'),
          isMultiValued: true,
          requiresTranslation: true
        },
        {
          field: 'contentType', // field name in content
          label: this.$t('content.contentType'),
          isMultiValued: false,
          requiresTranslation: true
        }
      ]
    },

    contentMixin_sorts() {
      return [
        {
          text: this.$t('content.sortOptions.sortByPublishDateAsc'),
          value: 'sortByPublishDateAsc'
        },
        {
          text: this.$t('content.sortOptions.sortByPublishDateDesc'),
          value: 'sortByPublishDateDesc',
          default: true
        },
        /*
        {
          text: this.$t('content.sortOptions.sortByRankAsc'),
          value: 'sortByRankAsc'
        },
        {
          text: this.$t('content.sortOptions.sortByRankDesc'),
          value: 'sortByRankDesc'
        },
        */
        {
          text: this.$t('content.sortOptions.sortByTitleAsc'),
          value: 'sortByTitleAsc'
        },
        {
          text: this.$t('content.sortOptions.sortByTitleDesc'),
          value: 'sortByTitleDesc'
        }
      ]
    }
  }
}

import { saveState } from '@/store/persistence/stateManager.js'

const skipMutations = ['setLoading']
const skipModules = []

// function that creates a store plugin
export function createPolyglotPlugin() {
  return (store) => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
      // called after every mutation
      // (the mutation comes in the format of `{ type, payload }`)
      const parts = mutation.type.split('/')
      const [moduleName, mutationName] = parts.length > 1 ? parts : ['root', parts[0]]

      // avoid persisting state:
      //   1)redundantly during session initialization ("syncState")
      //   2)for "loading" states ("setLoading")
      if (skipMutations.includes(mutationName)) return
      if (skipModules.includes(moduleName)) return
      console.debug(`[store]: ${moduleName}:${mutationName} state=`, state[moduleName])

      // console.debug(`[store]: ${module}:${action} = ${mutation.payload}`)
      saveState(moduleName, state[moduleName])
    })
  }
}

export default {
  /* common mutations */

  syncState(state, newState) {
    state.posts = [...newState.posts]
  },

  /* specific mutations */

  postComment: (state, comment) => {
    const postIndex = state.posts.findIndex((post) => post.id === comment.postId)

    if (postIndex >= 0) {
      state.posts[postIndex].comments.push(comment)
    }
  },

  setPosts: (state, posts) => {
    state.posts = [...posts]
  },

  setLoading: (state, { loading, promise = null }) => {
    state.loading = loading
    state.promise = promise
  }
}

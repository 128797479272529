<template>
  <v-carousel
    v-model="slide"
    class="c-carousel"
    cycle
    height="100%"
    :hide-delimiters="isSingle"
    hide-delimiter-background
    interval="3000"
    :show-arrows="!isSingle"
    show-arrows-on-hover
    :touch="{
      left: () => slide--,
      right: () => slide++
    }"
  >
    <v-carousel-item
      v-for="(ad, index) in ads"
      :key="ad.id"
      class="c-item"
    >
      <slot name="ad" :ad="ad" :index="index"/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'AdCarousel',

  components: {},

  props: {
    ads: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      slide: 0
    }
  },

  computed: {
    isSingle() {
      return this.ads.length === 1
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-item {
  position: relative;
  padding: 0px;
  margin: 0px;
}

.c-carousel :deep(.v-carousel__controls) {
  visibility: hidden;
}
.c-carousel:hover :deep(.v-carousel__controls) {
  visibility: visible;
}
</style>

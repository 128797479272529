<template>
  <div class="c-youtube-player">
    <youtube
      width="100%"
      height="100%"
      :video-id="videoId"
    />
  </div>
</template>

<script>
import getId from 'get-youtube-id'

export default {
  name: 'YouTubeBlock',

  props: {
    url: {
      type: String,
      default: () => ''
    }
  },

  data: function () {
    return {
      videoId: getId(this.url)
    }
  }
}
</script>

<style lang="css">
.c-youtube-player > iframe {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<style lang="css" scoped>
.c-youtube-player {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--v-accent-base);
  border-style: solid;
  margin-bottom: 24px;
}
</style>

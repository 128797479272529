<template>
  <div id="app-layout" class="c-app-layout" :style="bottomHeightStyle">
    <AppSystemBar :message="message" />

    <AppSideBar v-if="!isReallyMobile" :show="showMenu" />

    <AppTopBar :menu="showMenu" :title="title" :subtitle="subtitle" @toggle="toggleMenu" />

    <AppMain @count="subtitle = `${$event}`" />

    <AppFooter v-if="showFooter" />

    <AppBottomBar v-if="isReallyMobile" :show="showMenu" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppSystemBar from '@/components/app/AppSystemBar'
import AppTopBar from '@/components/app/AppTopBar'
import AppMain from '@/components/app/AppMain'
import AppSideBar from '@/components/app/AppSideBar'
import AppBottomBar from '@/components/app/AppBottomBar'
import AppFooter from '@/components/app/AppFooter'

import MessageService from '@/services/messageService.js'
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'AppLayout',

  components: {
    AppSystemBar,
    AppTopBar,
    AppMain,
    AppSideBar,
    AppBottomBar,
    AppFooter
  },

  mixins: [mobileMixin],

  data: function () {
    return {
      leftMargin: 200,
      message: {},
      showMenu: true,
      showFooter: false,
      title: '',
      subtitle: ''
    }
  },

  computed: {
    ...mapGetters('menuStore', ['getMenuItemTitle']),
    ...mapGetters('userStore', ['getSetting']),

    locale() {
      return this.$store.state.i18nStore.locale
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    bottomHeightStyle() {
      return {
        // return either the height of the mobile menu bar or the footer
        '--c-bottom-height': this.isReallyMobile
          ? 'var(--c-bottom-bar-height);'
          : 'var(--c-footer-height);'
      }
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler: function (to, _from) {
        this.title = this.getMenuItemTitle(to, this.locale)
        this.subtitle = '' // clear the count until the new route recalculates it
      }
    }
  },

  created: function () {
    this.messageService = new MessageService()

    this.messageService.fetchSystemMessage().then((message) => {
      if (Object.keys(message).length > 0) {
        this.message = message
        this.enabled = true
      }
    })

    this.showMenu = this.getSetting('showMenu')
  },

  methods: {
    ...mapActions('userStore', ['updateSettings']),

    toggleMenu() {
      this.showMenu = !this.showMenu
      this.updateSettings({ showMenu: this.showMenu })
    }
  }
}
</script>

<style lang="css">
:root {
  --c-top-bar-height: 48px;
  --c-bottom-bar-height: 56px;
  --c-footer-height: 48px;
  --c-footer-height: 0px;
  --c-side-bar-mini-width: 80px;
  --c-side-bar-full-width: 256px;
}

.c-app-layout {
  height: 100%;
  overflow-y: auto;
  /* DELETE ME
  min-height: 100vh;
  */
}
</style>

<template>
  <SectionPanel
    :colour="colour"
    :title="title"
    :count="postCount"
    :loading="arePostsLoading"
  >
    <template #toolbar>
      <LayoutToolbar
        v-model="postLayout"
        :dial="false"
        :items="userFavouritePosts"
        :filters="postFilters"
        :layouts="layouts"
        :sort="postSortOptionName"
        :sorts="postExtendedSortOptions"
        @results="postResultSet = $event"
        @update:layout="updatePreferences({ favouritePostsLayout: $event })"
        @update:sort="updatePreferences({ favouritePostsSort: $event })"
      />
    </template>
    <CardGrid
      v-if="postLayout === 'grid'"
      :items="postResultSet"
      :card-width="300"
    >
      <template #card="props">
        <DraggableNode
          :active="arePostsDraggable"
          drag-type="post"
          :identifier="props.item.id"
          @drop="movePost({ from: $event.dragId, after: $event.dropId })"
        >
          <PostCardSkeleton v-if="arePostsLoading" />
          <PostCard
            v-else
            :post="props.item"
            @view:post="$emit('view:post', props.item)"
          />
        </DraggableNode>
      </template>
    </CardGrid>
    <CardCarousel
      v-else
      class="pa-4"
      title="home.posts"
      :items="postResultSet"
      :card-width="300"
    >
      <template #card="props">
        <PostCardSkeleton v-if="arePostsLoading" />
        <PostCard
          v-else
          :post="props.item"
          @view:post="$emit('view:post', props.item)"
        />
      </template>
    </CardCarousel>
  </SectionPanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// post components
import PostCard from '@/components/post/PostCard'
import PostCardSkeleton from '@/components/post/PostCardSkeleton'

// base components
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import DraggableNode from '@/components/base/DraggableNode'
import SectionPanel from '@/components/base/SectionPanel'
import LayoutToolbar from '@/components/base/LayoutToolbar'

import postMixin from '@/components/post/postMixin.js'

export default {
  name: 'PostPanel',

  components: {
    // post components
    PostCard,
    PostCardSkeleton,
    // base components
    CardCarousel,
    CardGrid,
    DraggableNode,
    LayoutToolbar,
    SectionPanel
  },

  mixins: [postMixin],

  props: {
    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'tree']
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      arePostsLoading: true,
      dummyPosts: new Array(48).fill().map((_, index) => ({ id: index.toString() })),
      postResultSet: [],
      postCount: 0,
      postLayout: ''
    }
  },

  computed: {
    ...mapGetters('userStore', ['getPreference']),

    posts() {
      return this.$store.state.postStore.posts
    },

    arePostsDraggable() {
      // only allow dragging if all favourites are visible (unfiltered or excluded via search)
      // and the sort order is "as is"
      return (
        this.userFavouritePosts.length === this.postResultSet.length &&
        this.postSortOptionName === 'sortByAsIs'
      )
    },

    /* layout settings */

    postFilters() {
      return this.postMixin_filters
    },

    postExtendedSortOptions() {
      return [
        ...this.postMixin_sorts,
        {
          text: this.$t('ui.sortOptions.sortByAsIs'),
          value: 'sortByAsIs'
        }
      ]
    },

    postSortOptionName() {
      return this.getPreference('favouritePostsSort')
    },

    /* maintain favourites */

    userFavouritePostsIds() {
      return this.$store.state.userStore.posts
    },

    userFavouritePosts() {
      return this.posts
        .filter((post) => this.userFavouritePostsIds.includes(post.id))
        .sort(
          (a, b) =>
            this.userFavouritePostsIds.indexOf(a.id) - this.userFavouritePostsIds.indexOf(b.id)
        )
    },

    userFavouritePostsCount() {
      return this.userFavouritePosts.length
    }
  },

  watch: {
    postResultSet: {
      immediate: false,
      handler: function (newResultSet, _oldValue) {
        this.postCount = newResultSet.length
      }
    }
  },

  created: async function () {
    this.postResultSet = this.dummyPosts
    this.postLayout = this.getPreference('favouritePostsLayout')
    await this.fetchPosts({ resync: false })
    this.postResultSet = this.userFavouritePosts
    this.arePostsLoading = false
  },

  methods: {
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('userStore', ['updatePreferences', 'movePost'])
  }
}
</script>

<style lang="css" scoped></style>

<template>
  <div class="c-content-details">
    <h2 class="text-heading-2 text-left mb-4">
      {{ heading }}
    </h2>

    <PortableText v-if="hasDescription" :blocks="description" />

    <ContentTabs v-if="hasTabs" :tabs="tabs" />
  </div>
</template>

<script>
import ContentTabs from '@/components/content/ContentTabs'
import PortableText from 'sanity-blocks-vue-component'

export default {
  name: 'ContentDetails',

  components: {
    ContentTabs,
    PortableText
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    heading() {
      return this.item.details?.heading || this.item.title
    },

    description() {
      return this.item.details?.description || []
    },

    hasDescription() {
      return this.description.length > 0
    },

    tabs() {
      return this.item.details?.tabs || []
    },

    hasTabs() {
      return this.item.details?.tabs?.length > 0
    }
  }
}
</script>

<style lang="css" scoped>
</style>

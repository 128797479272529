// import client from '@client'
import client from '@/services/real'

class ChannelService {
  constructor() {
    this.type = 'sanity'
  }

  fetchChannels = async ({ filter }) => {
    const channels = await client.fetchChannels({ filter })
    return channels
  }

  fetchChannel = async (id) => {
    const channel = await client.fetchChannel(id)
    return channel.id !== undefined ? channel : null
  }
}

export default ChannelService
export const channelService = new ChannelService()

// import AuthService from '@/services/authService'
import AuthnService from '@/services/authnService'
// import ProfileService from '@/services/profileService'

// const authService = new AuthService()
const authnService = new AuthnService()

/*
const initialize = async (store) => {
  try {
    const tenant = await store.dispatch('tenantStore/fetchTenant', { resync: true })
    // await authService.initialize(tenant)
    await authnService.initialize(tenant)
  } catch (error) {
    console.error('[authPlugin]: Initialization failed!', error)
  }
}
*/

export class AuthPlugin {
  static install(Vue, _options) {
    // initialize(store)
    // Vue.prototype.$authn = authService
    Vue.prototype.$auth = authnService
    // Vue.prototype.$profile = profileService
  }
}

<template>
  <v-card class="d-flex justify-left flex-wrap">
    <v-img class="c-logo mr-4" contain :lazy-src="require('@/assets/images/icons/avatar.png')" :src="company.logo" />
    <v-card-title class="px-0 c-name">
      {{ company.name }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'CompanyAvatar',

  components: {},

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {}
}
</script>

<style lang="css" scoped>
.c-logo {
  max-width: 72px;
  max-height: 72px;
}
.c-name {
  font-size: 1.25rem;
}
</style>

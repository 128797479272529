<template>
  <div class="c-grid-wrapper">
    <v-container ref="grid" class="px-1 py-1" :class="wrapperClass" fluid>
      <v-row class="c-grid g-hide-scrollbar ma-0" align="stretch" justify="start">
        <v-col v-for="item in itemSubList" :key="item.id" :cols="cols">
          <slot name="card" :item="item" />
        </v-col>
      </v-row>
    </v-container>
    <div v-if="areMoreItems" class="c-progress text-center my-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="50"
        :width="8"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardGrid',

  components: {},

  props: {
    items: {
      type: Array,
      required: true
    },

    scroll: {
      type: Number,
      required: false,
      default: 48
    },

    wrapperClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      startIndex: 0,
      endIndex: this.scroll,
      cardWidth: 300,
      cardHeight: 400,
      itemsPerRow: 1,
      itemsPerView: 1,
      gridWidth: 0
    }
  },

  computed: {
    cols() {
      if (this.gridWidth > 4152) return 1 // 12 columns
      if (this.gridWidth > 1904) return 2 // 6 columns
      if (this.gridWidth > 1264) return 3 // 4 columns
      if (this.gridWidth > 960) return 4 // 3 columns
      if (this.gridWidth > 600) return 6 // 2 columns
      if (this.gridWidth > 450) return 12 // 1 column
      return 12
    },

    cols1() {
      const xxs = 450
      const xxl = 4152 // (12 * 300px) + (16px + 11 * 24px + 16px) + 256px

      const columns = {
        xxs: 12, // 1 card per row (<  450 px)
        xs: 12, // 1 cards per row (<  600 px)
        sm: 6, // 2 cards per row  (<  960 px)
        md: 4, // 3 cards per row  (< 1264 px)
        lg: 3, // 4 cards per row  (< 1904 px)
        xl: 2, // 6 cards per row  (< 4152 px)
        xxl: 1 // 12 cards per row (> 4152 px)
      }

      return this.$vuetify.breakpoint.width > xxl
        ? columns.xxl
        : this.$vuetify.breakpoint.width < xxs
        ? columns.xxs
        : columns[this.$vuetify.breakpoint.name]
    },

    itemSubList() {
      return this.items.slice(this.startIndex, this.endIndex)
    },

    areMoreItems() {
      return this.itemSubList.length < this.items.length
    }
  },

  mounted: async function () {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)

    // this.$nextTick(() => this.onResize())
    await this.$nextTick()
    this.onResize()
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onResize() {
      this.gridWidth = this.$refs.grid.clientWidth
    },

    onScroll() {
      // retrieve grid dimensions
      const gridRect = this.$refs.grid.getBoundingClientRect()
      const grid = {
        top: gridRect.top,
        bottom: gridRect.bottom,
        width: gridRect.right - gridRect.left,
        height: gridRect.bottom - gridRect.top
      }

      this.itemsPerRow = Math.floor(grid.width / this.cardWidth)
      this.itemsPerView = Math.floor((grid.height / this.cardHeight) * this.itemsPerRow)

      // extend the items slice (use a 2 row buffer)
      if (grid.bottom < window.innerHeight + 2 * this.cardHeight) {
        this.endIndex += this.itemsPerView
      }
    },

    resetScrolling() {
      this.endIndex = this.scroll
    },

    // not used - but a larger grid rendering may require it
    updateVisibleIndex() {
      const gridEl = this.$el
      const grid = {
        top: gridEl.scrollTop,
        bottom: gridEl.scrollTop + gridEl.clientHeight,
        width: gridEl.clientWidth
      }

      // this.itemsPerRow = Math.floor(grid.width / 300)
      this.itemsPerRow = this.cols
      this.itemsPerView = Math.ceil((grid.bottom / this.itemHeight) * this.itemsPerRow)
      console.debug('[Content]: itemsPerRow=', this.itemsPerRow)
      console.debug(`[Content]: (top,bottom) = (${grid.top},${grid.bottom})`)

      // determine the subset of items to render
      this.startIndex = Math.floor((grid.top / this.itemHeight) * this.itemsPerRow)
      this.endIndex = Math.ceil((grid.bottom / this.itemHeight) * this.itemsPerRow)
      console.debug(`[Content]: (start,end) = (${this.startIndex},${this.endIndex})`)

      // add padding to the top (no need to render cards that are offscreen)
      this.containerStyle = {
        paddingTop: this.startIndex * this.itemHeight + 'px'
        // height: this.items.length * this.itemHeight + 'px'
      }
      console.debug(this.items.length, this.startIndex, this.endIndex)
    }
  }
}
</script>

<style lang="css" scoped>
.c-grid-wrapper {
  height: 100%;
}
</style>

<template>
  <div class="c-unity-player c-unity-wrapper">
    <UnityApp
      ref="unityApp"
      :src="src"
      @loaded="isLoaded = true"
      @error="$emit('error:handled')"
    />

    <SceneInfo v-show="showInfo" v-model="showInfo" class="c-popup">
      {{ description }}
    </SceneInfo>

    <v-footer
      v-if="!hideControls && isLoaded"
      class="c-footer d-flex"
      absolute
      color="rgba(0,0,0,0)"
      inset
      padless
      tile
    >
      <div class="hidden-xs-only text-right mb-1 ml-1">
        <v-img
          class="c-invisible d-inline-block"
          contain
          :src="orgLogo"
          max-height="48px"
          max-width="48px"
        />
      </div>
      <v-spacer />
      <div class="text-center mb-1">
        <ModelControls
          v-if="isLoaded"
          v-model="showInfo"
          :rerender="rerender"
          :scenes="scenes"
          :initial-scene-id="firstSceneId"
          @info="description = $event"
          @message="sendUnityMessage($event)"
        />
      </div>
      <v-spacer />
      <div class="hidden-xs-only text-right mb-1 mr-1">
        <v-img
          class="d-inline-block"
          contain
          :src="orgLogo"
          max-height="48px"
          max-width="48px"
        />
      </div>
    </v-footer>
  </div>
</template>

<script>
import SceneInfo from '@/players/ModelPlayer/components/SceneInfo'
import ModelControls from '@/players/ModelPlayer/components/ModelControls'
import UnityApp from './components/UnityApp'
import { EventNames } from '@/players/ModelPlayer/constants/ControlConstants'

export default {
  name: 'UnityPlayer',

  components: {
    SceneInfo,
    ModelControls,
    UnityApp
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isLoaded: false,
      description: '',
      params: [],
      scenes: [],
      hideControls: false,
      rerender: 0,
      showInfo: false,
      src: this.content.contentURL
    }
  },

  computed: {
    firstSceneId() {
      return this.scenes.find((scene) => scene.id)?.id || ''
    },

    orgLogo() {
      return this.$store.state.orgStore.orgLogo
    }
  },

  created: async function () {
    // fetch the (optional) model config file
    try {
      const config = await this.fetchModelConfig()
      console.debug('[UnityPlayer]: model.json=', config)
      this.hideControls = !!config.hideControls
      this.scenes = config.scenes || []
      this.$emit('context', {
        sceneId: this.firstSceneId,
        scenes: this.scenes
      })
    } catch (error) {
      console.error('[UnityPlayer]: Error loading model config file.')
    }

    // fetch the content params
    this.params = this.parseParams(this.content.params)
  },

  mounted: function () {
    // Note: the following two listeners are not used by Lumenii

    // listen for external scene selection
    this.$bus.$on('select:scene', (sceneId) => {
      this.sendUnityMessage({ eventName: EventNames.LOAD_SCENE, param: sceneId })

      // force model rendering
      this.rerender += 1
    })

    // listen for external animation selection
    this.$bus.$on('select:animation', (animationId) => {
      this.sendUnityMessage({ eventName: EventNames.TOGGLE_ANIMATION, param: animationId })

      // force model rendering
      this.rerender += 1
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('select:scene')
    this.$bus.$off('select:animation')
  },

  methods: {
    async fetchModelConfig() {
      try {
        const modelURL = this.content.contentURL
        const modelPath = modelURL.substring(0, modelURL.lastIndexOf('/Build/'))
        const response = await fetch(
          `/api/tenant/${this.$store.state.tenantStore.tenantKey}/model?path=${modelPath}`
        )
        if (response.ok) {
          return response.json()
        } else if (response.status === 404) {
          return {} // model.json is optional, so no error is returned
        } else {
          throw new Error(response.statusText)
        }
      } catch (error) {
        console.error('[UnityPlayer]: ', error)
        throw error
      }
    },

    parseParams(params) {
      if (!params) return []

      return params.map((param) => {
        const parts = param.split(':')
        const key = parts[0]
        const value = parts[1] === 'false' ? false : parts[1] === 'true' ? true : parts[1]
        return { [key]: value }
      })
    },

    sendUnityMessage(message) {
      this.$refs.unityApp.sendUnityMessage({ event: message.eventName, param: message.param })
    }
  }
}
</script>

<style lang="css" scoped>
.c-unity-wrapper {
  position: relative;
  height: calc(100vh - var(--c-player-bar-height, 56px));
  height: calc(-webkit-fill-available - var(--c-player-bar-height, 56px));
}

.c-footer {
  z-index: 3;
}

.c-invisible {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    padding-bottom: 65px;
  }
}
</style>

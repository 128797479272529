<template>
  <div v-show="showAds" ref=block class="c-ad-block">
    <div ref="ads" class="c-ads">
      <AdStack
        v-if="stack"
        class="c-ad-stack"
        :class="{ 'c-outline px-4': outline }"
        :ads="randomizedAds"
        :header="header"
        :rounded="outline"
        @close="onCloseAds()"
      >
        <template #ad="props">
          <AdImage
            class="c-ad-image"
            :data-ad="props.index"
            :ad="props.ad"
            :close="false"
            @load="onLoadAd($event)"
            @select="onSelectAd($event)"
          />
          <AdCopy
            v-if="props.ad.adItem.copy"
            :copy="props.ad.adItem.copy"
          />
        </template>
      </AdStack>
      <AdCarousel
        v-else-if="carousel"
        class="c-ad-carousel"
        :ads="showHeroAd ? heroAds : randomizedAds"
        @close="onCloseAds()"
        @select="onSelectAd($event)"
      >
        <template #ad="props">
          <AdImage
            class="c-ad-image"
            :data-ad="props.index"
            :ad="props.ad"
            :aspect-ratio="showHeroAd ? '' : aspectRatio"
            close
            @close="onCloseAds()"
            @load="onLoadAd($event)"
            @select="onSelectAd($event)"
          />
        </template>
      </AdCarousel>
      <AdImage
        v-else-if="image && singleAd"
        class="c-ad-image"
        data-ad="0"
        :ad="singleAd"
        :aspect-ratio="aspectRatio"
        close
        @close="onCloseAds()"
        @load="onLoadAd($event)"
        @select="onSelectAd($event)"
      />
    </div>
    <AdModal
      v-model="showModal"
      :src="modalSrc"
    />
  </div>
</template>

<script>
import AdCarousel from '@/components/ad/AdCarousel'
import AdCopy from '@/components/ad/AdCopy'
import AdImage from '@/components/ad/AdImage'
import AdModal from '@/components/ad/AdModal'
import AdStack from '@/components/ad/AdStack'
import { trackingId } from '@/config/appConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'AdBlock',

  components: {
    AdCarousel,
    AdCopy,
    AdImage,
    AdModal,
    AdStack
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    aspectRatio: {
      type: String,
      required: false,
      default: ''
    },

    max: {
      type: Number,
      required: false,
      default: 3
    },

    carousel: {
      type: Boolean,
      required: false,
      default: false
    },

    header: {
      type: Boolean,
      required: false,
      default: true
    },

    hero: {
      type: Boolean,
      required: false,
      default: false
    },

    image: {
      type: Boolean,
      required: false,
      default: false
    },

    outline: {
      type: Boolean,
      required: false,
      default: true
    },

    reverse: {
      type: Boolean,
      required: false,
      default: false
    },

    stack: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: false,
      default: true
    },

    zone: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      // hero ad processing
      showHeroAd: false,

      // modal for ad click content
      modalSrc: '',
      showModal: false,

      // ad observers
      resizeObserver: null,
      visibilityObserver: null
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAds']),

    ads() {
      // need to access adStore to ensure reactivity to ad inventory
      const adInventory = this.$store.state.adStore.ads
      return adInventory.length > 0 ? this.getAds(this.zone) : []
    },

    adsReversed() {
      return this.ads.slice().reverse()
    },

    heroAds() {
      // need to access adStore to ensure reactivity to ad inventory
      const adInventory = this.$store.state.adStore.ads
      return this.hero && adInventory.length > 0 ? this.getAds('hero') : []
    },

    singleAd() {
      return this.showHeroAd
        ? this.heroAds.length > 0 && this.heroAds[0]
        : this.ads.length > 0 && this.randomizedAds[0]
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    randomizedAds() {
      return this.ads.length > this.max
        ? this.getRandomSubarray(this.ads, this.max)
        : this.reversed
        ? this.adsReversed
        : this.ads
    },

    showAds() {
      return this.show && this.ads.length > 0
    },

    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },

  watch: {
    ads: {
      /* must be lazy because event is emitted */
      immediate: false,
      handler: function (newAds, _oldAds) {
        if (newAds.length === 0) {
          this.$emit('empty')
        }
      }
    },

    heroAds: {
      immediate: true,
      handler: function (heroAds, _oldHeroAds) {
        if (heroAds.length > 0 && this.zone === 'leaderboard') {
          this.showHeroAd = true
          this.startHeroTransition()
        }
      }
    }
  },

  created: function () {
    this.resizeObserver = new ResizeObserver(this.onResize)
    this.visibilityObserver = new IntersectionObserver(this.onIntersection, {
      root: null,
      threshold: 0.66
    })
  },

  mounted: function () {
    this.resizeObserver.observe(this.$refs.ads)
  },

  beforeDestroy: function () {
    this.resizeObserver.disconnect()
    this.visibilityObserver.disconnect()
  },

  methods: {
    // ad selection

    getRandomSubarray(array, size) {
      const results = []
      const sampled = {}
      // select up to the requested size OR the supplied array length (whichever is less)
      while (results.length < size && results.length < array.length) {
        // FIXME: could loop forever...pick from unselected indexes instead
        const index = Math.trunc(Math.random() * array.length)
        // avoid duplicates
        if (!sampled[index]) {
          results.push(array[index])
          sampled[index] = true
        }
      }
      return results
    },

    onCloseAds() {
      this.$emit('update:show', false)
    },

    onCloseModal() {
      this.showModal = false
    },

    onLoadAd(image) {
      this.visibilityObserver.observe(image)
    },

    onSelectAd(ad) {
      this.modalSrc = ad.adItem.cta?.href || ''
      console.warn(`[AdBlock]: ${this.zone} clicked! ad=`, ad)
      if (ad.adItem.cta?.href) {
        ad.adItem.cta.popup
          ? (this.showModal = true)
          : window.open(ad.adItem.cta.href, 'LumeniiAdWindow', 'popup')
      } else if (ad.adItem.cta?.link) {
        this.$router.push(ad.adItem.cta.link)
      }
      this.sendBeacon(ad, 'adClick')
    },

    onViewAd(ad) {
      this.sendBeacon(ad, 'adView')
    },

    startHeroTransition() {
      setTimeout(() => {
        this.showHeroAd = false
      }, 3000)
    },

    // resize methods

    onResize(entries, _observer) {
      const height = Math.ceil(entries[0].contentRect.height)
      this.$emit('height', height)
    },

    // analytics methods

    onIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.isVisible(entry.target)) {
            const ad = this.ads[entry.target.dataset.ad]
            console.warn(`[AdBlock]: ${this.zone} impression! ad=`, ad)
            this.onViewAd(ad)
            observer.unobserve(entry.target)
          }
        }
      })
    },

    isVisible(el) {
      const style = getComputedStyle(el)

      if (el.clientHeight === 0) return false
      if (style.display === 'none') return false
      if (style.visibility !== 'visible') return false
      if (style.opacity < 0.1) return false

      return true
    },

    sendBeacon(ad, eventName) {
      const url = 'https://www.healthplexus.net/ad/track/index.php'

      // define analytics context
      const body = {
        // tracking event (e.g. 'adClick', 'adView')
        event: eventName,

        // ad specific context
        ad_name: ad.title,
        ad_group: ad.group,
        ad_zone: this.zone,

        // invocation context
        path: this.$route.path,
        token: 'abc123',
        tid: trackingId,

        // user context
        tz: this.timezone,
        uid: this.$auth.getUserSubject(),

        // Urchin codes
        utm_campaign: this.$route.query.utm_campaign || '',
        utm_medium: this.$route.query.utm_medium || 'pwa',
        utm_source: this.$route.query.utm_source || this.portalKey
      }

      // construct form data
      const formData = new FormData()
      for (const [key, value] of Object.entries(body)) {
        formData.append(key, value)
      }

      // fire POST event
      if (navigator.sendBeacon) {
        const result = navigator.sendBeacon(url, formData)
        if (!result) {
          console.error('[AdBlock]: Call to sendBeacon() failed.')
        }
      } else {
        fetch(url, {
          method: 'post',
          body: formData,
          mode: 'no-cors',
          keepalive: true
        })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-ads {
  width: 100%;
}
.c-outline {
  box-shadow: 0 0 0 1pt var(--v-outline-base);
  border-radius: 16px;
  /* outline: 1px solid var(--v-outline-base); */
}
</style>

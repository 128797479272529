<template>
  <v-card-actions class="justify-space-between">
    <!-- view -->
    <v-btn icon large @click="$emit('view:item')">
      <v-icon color="accent" large> mdi-eye </v-icon>
    </v-btn>
    <!-- info -->
    <v-btn color="accent" icon large @click="$emit('view:info')">
      <v-icon large> mdi-information </v-icon>
    </v-btn>
    <!-- download -->
    <v-btn v-if="isPWA" color="accent" icon large @click="archiveItem(item)">
      <v-icon large> mdi-cloud-download </v-icon>
    </v-btn>
    <!-- favourite -->
    <v-btn color="red" icon large @click="toggleFavourite(item)">
      <v-icon large>
        {{ isFavourite ? 'mdi-heart' : 'mdi-heart-outline' }}
      </v-icon>
    </v-btn>
    <!-- share -->
    <v-btn color="accent" icon large @click="$emit('share')">
      <v-icon large> mdi-share-variant </v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContentCardActions',

  components: {},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false
    }
  },

  computed: {
    isPWA() {
      return this.$feature('pwa')
    },

    favourite() {
      return this.$store.state.userStore.favourites.indexOf(this.item.id) > -1
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToFavourites', 'removeFromFavourites']),

    /*
     * manage favourites
     */
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToUserFavourites() : this.removeFromUserFavourites()
    },

    addToUserFavourites() {
      this.addToFavourites({ id: this.item.id })
      this.$emit('add:favourite')
    },

    removeFromUserFavourites() {
      this.removeFromFavourites({ id: this.item.id })
      this.$emit('remove:favourite')
    },

    /*
     * manage storage
     */
    archiveItem() {},
    downloadItem() {}
  }
}
</script>

<style lang="css" scoped></style>

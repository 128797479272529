<template>
  <div
    ref="viewer"
    class="c-article-viewer c-container"
    :class="[isDark ? 'c-dark' : 'c-light']"
  >
    <AdBlock
      v-if="showTopAds"
      ref="leaderboard"
      class="c-leaderboard"
      :class="{ 'c-no-sides': !showSideAds }"
      carousel
      hero
      :max="3"
      zone="leaderboard"
      :show="showTopAds"
      @update:show="onCloseLeaderboard($event)"
      @height="setFrameHeight($event)"
    />
    <div ref="columns" class="c-columns">
      <!-- supplemental -->
      <div
        ref="leftSide"
        class="c-side c-left-side"
        :class="{ 'c-collapsed': !showAuthors && !showQuiz }"
      >
        <div
          v-if="hasQuiz"
          class="c-quiz-block c-outline d-flex flex-column px-2 py-0 mb-4"
        >
          <div class="pt-2 my-auto text-caption text-center text-uppercase">
            {{ $t('content.quiz').toUpperCase() }}
          </div>
          <QuizCard />
        </div>
        <div
          v-if="hasAuthors"
          class="c-author-block c-outline d-flex flex-column pa-2 pt-0"
        >
          <div class="pt-2 my-auto text-caption text-center text-uppercase">
            {{ $t('content.authors').toUpperCase() }} / {{ $t('content.advisors').toUpperCase() }}
          </div>
          <div
            v-for="author in authors"
            :key="author.id"
            class="c-author d-flex flex-wrap my-2"
          >
            <PersonAvatar v-if="useAvatar" class="c-author-avatar" :person="author" :size="50" />
            <PersonCard v-else class="c-author-card" :person="author" />
          </div>
        </div>
      </div>

      <!-- article -->
      <div
        ref="middle"
        class="c-middle"
      >
        <article
          ref="article"
          class="c-article"
          :class="{'c-noAds': noAds || !showSideAds}">
          <iframe
            ref="iframe"
            class="ma-auto"
            allowfullscreen
            frameborder="0"
            :src="src"
            :title="title"
          />
        </article>
      </div>

      <!-- ads -->
      <div
        ref="rightSide"
        class="c-side c-right-side"
        :class="{'c-empty': noAds, 'c-collapsed': !showSideAds }"
      >
        <AdBlock
          class="c-ad-block"
          :max="5"
          stack
          zone="side"
          :show="showSideAds"
          @empty="noAds = true"
          @update:show="onCloseSides($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdBlock from '@/components/ad/AdBlock'
import PersonAvatar from '@/components/base/PersonAvatar'
import PersonCard from '@/components/person/PersonCard'
import QuizCard from '@/components/quiz/QuizCard'

import { playerBarHeight as PLAYER_BAR_HEIGHT } from '@/config/appConfig.js'
import playerMixin from '@/mixins/playerMixin'

const ROUNDING_ERROR = 1
const SIDE_BUFFER = 24

export default {
  name: 'ArticleViewer',

  components: {
    AdBlock,
    PersonAvatar,
    PersonCard,
    QuizCard
  },

  mixins: [playerMixin],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      noAds: false,
      showAuthors: true,
      showQuiz: true,
      showHeroAds: true,
      showTopAds: true,
      showSideAds: true,
      useAvatar: true
    }
  },

  computed: {
    authors() {
      return this.content.authors?.slice().sort((a, b) => a.lastName > b.lastName)
    },

    hasAuthors() {
      return this.content.authors && this.content.authors.length > 0
    },

    quizId() {
      return this.content.params?.find((param) => param.name === 'quiz')?.value || ''
    },

    hasQuiz() {
      return !!this.quizId
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    src() {
      return this.content.contentURL
    },

    title() {
      return this.content.title
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.onResize)
    this.setFrameHeight(-1)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onCloseLeaderboard(closed) {
      if (!closed) {
        console.warn('[ArticleViewer]: closing leaderboard')
        this.showTopAds = false
        this.setFrameHeight(0)
      }
    },

    onCloseSides(closed) {
      // note: side divs are siblings to iframe parent
      // (so use $nextTick to resize parent)
      if (!closed) {
        console.warn('[ArticleViewer]: closing sides')
        this.showSideAds = false
        // wait for parent div to rerender (otherwise will reset height to 0)
        this.$nextTick(() => this.setFrameHeight(-1))
      }
    },

    onResize() {
      this.setFrameHeight(-1)
    },

    setFrameHeight(height) {
      const leaderboardHeight = height < 0 ? this.$refs.leaderboard?.$el?.clientHeight || 0 : height
      // const viewerHeight = this.$refs.viewer.clientHeight
      const innerHeight = window.innerHeight - PLAYER_BAR_HEIGHT
      const leftSideHeight = this.$refs.leftSide?.clientHeight + SIDE_BUFFER || 0
      const rightSideHeight = this.$refs.rightSide?.clientHeight + SIDE_BUFFER || 0
      const sideHeight = Math.max(leftSideHeight, rightSideHeight)
      const maxHeight = Math.max(innerHeight, sideHeight + leaderboardHeight)
      const iframeHeight = maxHeight - leaderboardHeight - ROUNDING_ERROR
      /*
      console.debug('****************')
      console.debug('frame1: viewerHeight=', viewerHeight)
      console.debug('frame1: innerHeight=', innerHeight)
      console.debug('frame1: topHeight=', leaderboardHeight)
      console.debug('frame1: leftSideHeight=', leftSideHeight)
      console.debug('frame1: rightSideHeight=', rightSideHeight)
      console.debug('frame1: sideHeight=', sideHeight)
      console.debug('frame1: maxHeight=', maxHeight)
      console.debug('frame1: iframeHeight=', iframeHeight)
      */
      // on some browsers, parent <article> div cannot be 0
      this.$refs.article.style.height = iframeHeight + 'px'
      this.$refs.middle.style.height = iframeHeight + 'px'
    }
  }
}
</script>

<style lang="css" scoped>
/* default is 100% width, no sides */
.c-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--v-sheet-base);
  width: 100%;
  height: 100%;
}
.c-columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transition: width 0.3s;
}
.c-columns::-webkit-scrollbar {
  display: none;
}
.c-leaderboard {
  width: 100%;
}

.c-ad-block {
  min-width: 150px;
}

/* author styles */
.c-author-card {
  flex: 1 1 0;
}
.c-author-avatar {
  padding: 4px;
  width: 100%;
}

/* middle default styles */
.c-middle {
  position: relative;
  width: 100%;
  height: 100%;
}
.c-middle article {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: hidden;
}
.c-middle article.c-scale {
  /* scale article since app default zoom is 0.75 */
  transform: scale(1.2);
  transform-origin: top;
  margin: auto;
  width: 80%;
}
.c-middle iframe {
  display: block; /* iframe is normally inline - which adds lineheight whitespace causing scrollbars */
  width: 100%;
  height: 100%;
  max-width: 900px;
}

/* sides */
.c-side {
  margin: 8px 16px;
  border-radius: 16px;
  min-width: 0; /* need to set minimum because flex-item defaults to min-width: content */
  max-width: 380px; /* 332 image + 2 * 24 padding + 0 border */
  width: 100%;
  padding: 0px;
  transition: width 0.3s;
}
.c-side.c-collapsed {
  width: 0;
  overflow: hidden;
}
.c-dark .c-quiz-block,
.c-dark .c-author-block,
.c-dark .c-ad-block {
  background-color: rgba(0, 0, 0, 0.25);
}
.c-light .c-quiz-block,
.c-light .c-author-block,
.c-light .c-ad-block {
  background-color: rgba(255, 255, 255, 0.5);
}

/* responsive styles */

@media only screen and (min-width: 0px) {
  .c-left-side {
    display: none;
  }
  .c-right-side {
    display: none;
  }
}

@media only screen and (min-width: 1080px) {
  .c-middle {
    min-width: 820px;
  }
  .c-right-side {
    display: block;
  }
  .c-right-side.c-empty {
    margin: 0;
    max-width: fit-content; /* handles case where no ads match */
  }
}

@media only screen and (min-width: 1500px) {
  .c-left-side {
    display: block;
  }
  .c-middle article.c-noAds {
    text-align: left;
  }
}

/* generic styles */
.c-outline {
  box-shadow: 0 0 0 1pt var(--v-outline-base);
  border-radius: 16px;
  /* outline: 1px solid var(--v-outline-base); */
}
</style>

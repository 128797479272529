<template>
  <v-bottom-navigation
    v-model="currentItem"
    app
    :background-color="isDark ? 'background' : '#f0f0f0'"
    color="accent"
    grow
    height="56"
    :input-value="show"
  >
    <v-btn
      v-for="item in baseMenuItems"
      :key="item.key"
      class="c-menu-item"
      active-class="c-selected-item"
      :to="to(item)"
      :value="item.key"
      @click.stop="selectBaseItem(item)"
    >
      <v-icon x-large>
        {{ item.icon }}
      </v-icon>
      <div v-if="item.translated">{{ item.title[locale] || item.title['en'] }}</div>
      <div v-else>{{ $t(`${item.title}`) }}</div>
    </v-btn>

    <v-btn
      v-if="hasExtensions"
      id="activator"
      class="c-menu-item"
      :active-class="extensionItem ? 'c-selected-item' : 'v-btn__active'"
      @click.stop="showExtensionMenu = !showExtensionMenu"
      @mouseover="hoverMenu = true"
      @mouseleave="hoverMenu = false"
    >
      <v-icon x-large> mdi-dots-vertical </v-icon>
      <div>{{ $t('ui.more') }}</div>
    </v-btn>

    <v-menu
      v-model="showExtensionMenu"
      class="c-menu"
      attach="#activator"
      nudge-right="24px"
      offset-y
      open-on-hover
      top
      transition="scroll-y-reverse-transition"
    >
      <v-list dense>
        <v-list-item
          v-for="item in extensionMenuItems"
          :key="item.key"
          class="mx-0 my-2 c-menu-item"
          active-class="c-selected-item"
          :to="to(item)"
          :value="item.key"
          @click="selectExtensionItem(item)"
        >
          <v-icon
            :class="[extensionItem === item.key ? 'c-btn--active' : 'c-btn--inactive']"
            x-large
          >
            {{ item.icon }}
          </v-icon>
          <div v-if="item.translated">{{ item.title[locale] || item.title['en'] }}</div>
          <div v-else>{{ $t(`${item.title}`) }}</div>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'AppBottomBar',

  components: {},

  props: {
    show: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      currentItem: null,
      extensionItem: null,
      baseItem: null,
      showExtensionMenu: false
    }
  },

  computed: {
    allItems() {
      const priorityItems = this.menuItems.filter((item) => item.priority)
      const secondaryItems = this.menuItems.filter((item) => !item.priority)
      return [...priorityItems, ...secondaryItems]
    },

    baseMenuItems() {
      return this.allItems.slice(0, this.itemCapacity)
    },

    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    currentRouteName() {
      return this.$route.name
    },

    extensionMenuItems() {
      return this.allItems.slice(this.itemCapacity).reverse()
    },

    hasExtensions() {
      return this.extensionMenuItems.length > 0
    },

    itemCapacity() {
      const capacity = {
        xs: 3,
        sm: 4,
        md: 8,
        lg: 10,
        xl: 12
      }

      return capacity[this.$vuetify.breakpoint.name] || this.allItems.length
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  created: function () {
    this.currentItem = this.currentRouteName
  },

  methods: {
    selectBaseItem(item) {
      this.baseItem = item.key
      this.extensionItem = null
    },

    selectExtensionItem(item) {
      this.baseItem = null
      this.extensionItem = item.key
      this.showExtensionMenu = false
    },

    toggleExtensionMenu() {
      this.showExtensionMenu = !this.showExtensionMenu
    },

    to(item) {
      return item.to ? item.to : { name: item.route }
    }
  }
}
</script>

<style lang="css" scoped>
.v-menu__content .v-list {
  background-color: var(--v-background-base);
}

.c-menu-item {
  font-size: 12px;
  height: 64px;
}

.c-menu-item.c-selected-item {
  color: var(--v-accent-base) !important;
}

.c-menu-item :deep(.v-btn__content),
.c-menu-item.v-list-item {
  display: flex;
  flex-direction: column !important;
}

.c-menu-item .v-list-item--active,
.c-menu-item .c-btn--active {
  color: var(--v-accent-base);
}
</style>

<template>
  <section v-if="isLoaded" class="c-quiz">

    <!-- transition -->
    <Transition
      name="bounce"
      mode="out-in"
    >
      <div class="c-cards">

        <!-- quiz header -->
        <v-card class="c-quiz-header mb-4" elevation="4">
          <v-card-title class="c-quiz-title d-flex flex-column">
            <!-- header -->
            <h1 class="text-h6">{{ quiz.title }}</h1>

            <p>{{ quiz.description }}</p>

            <div class="c-quiz-status d-flex flex-column justify-center align-center">
              <div>{{ quiz.questions.length }} Questions, {{ quiz.questions.length - answered() }} Remaining</div>
              <div>{{ answered() > 0 ? answered() + ' Answered' : ''}}</div>
            </div>

            <!-- progress -->
            <div class="c-progress d-flex flex-column align-center">
              <v-progress-linear
                class="c-progress-bar"
                color="success"
                height="8"
                :value="(currentQuestion / quiz.questions.length) * 100"
              />
              <p>
                {{ (currentQuestion / quiz.questions.length) * 100 }}% complete
              </p>
            </div>
          </v-card-title>
        </v-card>

        <!-- question card -->
        <v-card
          v-if="currentQuestion < quiz.questions.length"
          class="c-question-card mb-4"
          elevation="4"
        >
          <!-- question -->
          <v-card-title>
            <h2 class="text-h6">{{ quiz.questions[currentQuestion].question }}</h2>
          </v-card-title>

          <v-card-subtitle class="c-prompt pb-0 mt-0">
            {{ prompt }}
          </v-card-subtitle>

          <!-- responses -->
          <v-card-text class="c-question">
            <v-list class="px-0 py-0" shaped>
              <v-list-item-group
                v-model="userResponses[currentQuestion]"
                active-class="c-selected"
                color="accent"
                :multiple="isMulti"
              >
                <v-list-item
                  v-for="(response, index) in quiz.questions[currentQuestion].responses"
                  :key="index"
                  active-class="c-selected"
                >
                  <template #default="{ active }">
                    <v-list-item-content class="c-response-text">
                      {{ index | charIndex }}. {{ response.text }}
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="accent"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <!-- actions -->
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              color="primary"
              :disabled="currentQuestion < 1"
              @click="prev"
            >
              Back
            </v-btn>

            <v-btn
              color="primary"
              :disabled="currentQuestion >= quiz.questions.length"
              @click="next"
            >
              {{ unanswered ? 'Skip' : 'Next' }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- result card-->
        <v-card
          v-else
          class="c-result-card mb-4"
          elevation="4"
        >
          <v-card-title class="d-flex pa-2">
            <v-icon v-if="score() >= quiz.pass" color="success" x-large>
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" x-large>
              mdi-alpha-x-circle
            </v-icon>
            Your score: {{ formattedScore }} / {{ quiz.questions.length }}
          </v-card-title>

          <v-card-actions>
            <v-btn color="primary" @click="restart">
              {{ 'restart' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </Transition>

  </section>
</template>

<script>
import * as utils from '@/utilities'
import sampleQuiz from './SampleQuiz.json'

export default {
  name: 'QuizCard',

  components: {},

  filters: {
    charIndex: function (i) {
      return String.fromCharCode(97 + i)
    }
  },

  data: function () {
    return {
      quiz: {},
      currentQuestion: 0,
      userResponses: [],
      isActive: false,
      isLoaded: false
    }
  },

  computed: {
    isMulti() {
      return (
        this.quiz.questions[this.currentQuestion].responses.filter((response) => response.correct)
          .length > 1
      )
    },

    prompt() {
      return this.isMulti ? this.$t('quiz.prompts.multiple') : this.$t('quiz.prompts.single')
    },

    unanswered() {
      return (
        this.userResponses[this.currentQuestion] == null ||
        (Array.isArray(this.userResponses[this.currentQuestion]) &&
          this.userResponses[this.currentQuestion].length === 0)
      )
    },

    formattedScore() {
      return this.score()
        .toFixed(2)
        .replace(/[.,]00$/, '')
    }
  },

  created: async function () {
    this.quiz = await this.fetchQuiz()
    this.isLoaded = true
    console.warn('quiz=', this.quiz)
  },

  mounted: function () {
    const rgba = utils.hexToRGBA(this.$vuetify.theme.currentTheme.primary, 0.25)
    document.documentElement.style.setProperty('--c-primary-light', rgba)
  },

  methods: {
    async fetchQuiz() {
      this.userResponses = Array(sampleQuiz.questions.length).fill(null)
      return sampleQuiz
    },

    restart() {
      this.currentQuestion = 0
      this.userResponses = Array(this.quiz.questions.length).fill(null)
    },

    next() {
      if (this.currentQuestion < this.quiz.questions.length) this.currentQuestion++
    },

    prev() {
      if (this.quiz.questions.length > 0) this.currentQuestion--
    },

    answered() {
      return this.userResponses.reduce((answered, answer) => {
        if ((Array.isArray(answer) && answer.length > 0) || answer !== null) {
          answered = answered + 1
        }
        return answered
      }, 0)
    },

    score() {
      return this.userResponses.reduce((score, answer, i) => {
        if (answer && Array.isArray(answer)) {
          const quizCorrectAnswers = this.quiz.questions[i].responses.filter(
            (response) => response.correct
          ).length
          const userCorrectAnswers = answer.filter(
            (response) => this.quiz.questions[i].responses[response].correct
          ).length
          score += userCorrectAnswers / quizCorrectAnswers
        } else {
          if (answer && this.quiz.questions[i].responses[answer].correct) {
            score += 1
          }
        }
        return score
      }, 0)
    }
  }
}
</script>

<styles lang="scss" scoped>
.c-quiz {
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
	justify-content: center;
	overflow: hidden;
}

.c-progress {
  width: 100%;
}

.c-cards {
  width: 100%;
}

.c-question-card {
  height: 100%;
  width: 100%;
  white-space: normal;
}

.c-prompt {
  font-size: 1.25rem;
}
.c-response-text {
  font-size: 1rem;
}

.c-selected {
  background-color: var(--c-primary-light);
}

.c-result-card {
	text-align:center;
}

.c-actions {
	display: flex;
	justify-content: space-between;
}

/* transition: fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* transition: bounce */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

/* transistion: class */
.c-transition-animated {
   transition-duration: 0.3s;
}
</styles>

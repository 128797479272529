<template>
  <SectionPanels class="c-home-view" :depth="depth" :initial="[0, 1]">
    <!-- Favourite Content -->
    <ContentPanel
      v-if="showFavourites"
      class="c-panel"
      colour="secondary"
      :layouts="['grid', 'carousel']"
      :title="$t('home.favourites')"
      @share="shareItem($event)"
    />

    <!-- Favourite Posts -->
    <PostPanel
      v-if="showPosts"
      class="c-panel"
      colour="secondary"
      :layouts="['grid', 'carousel']"
      :title="$t('home.posts')"
      @view:post="viewPost($event)"
    />

    <!-- Calendar -->
    <SectionPanel
      v-if="showCalendar"
      class="c-panel"
      colour="secondary"
      :title="$t('home.today')"
      :count="userEventsCount"
      :loading="areEventsLoading"
    >
      <CalendarViewer :events="userEvents" period="day" @update:period="fetchCalendarToday" />
    </SectionPanel>

    <!-- Contacts -->
    <SectionPanel
      v-if="showContacts"
      class="c-panel"
      colour="secondary"
      :title="$t('home.contacts')"
      :count="userContactsCount"
      :loading="areContactsLoading"
    >
      <CardCarousel
        class="pa-4"
        title="home.contacts"
        :items="userContacts"
        :card-width="300"
        :rerender="areContactsLoading"
      >
        <template #card="props">
          <ClientCardSkeleton v-if="areContactsLoading" />
          <ContactCard v-else :contact="props.item" />
        </template>
      </CardCarousel>
    </SectionPanel>

    <!-- Companies -->
    <SectionPanel
      v-if="showCompanies"
      class="c-panel"
      colour="secondary"
      :title="$t('home.companies')"
      :count="userCompaniesCount"
      :loading="areCompaniesLoading"
    >
      <CardCarousel
        class="pa-4"
        title="home.companies"
        :items="userCompanies"
        :card-width="300"
        :rerender="areCompaniesLoading"
      >
        <template #card="props">
          <ClientCardSkeleton v-if="areCompaniesLoading" />
          <CompanyCard v-else :company="props.item" />
        </template>
      </CardCarousel>
    </SectionPanel>

    <!-- Modals -->
    <ContentModal v-if="showItemModal" v-model="showItemModal" :item="item" />
    <PostModal v-if="showPostModal" v-model="showPostModal" :post="post" />
    <ShareModal
      v-if="showSharingModal"
      v-model="showSharingModal"
      :entity-id="item.id"
      route-name="player"
      :subject="item.title"
      :body="item.abstract"
    />
  </SectionPanels>
</template>

<script>
import { mapActions } from 'vuex'
// contacts
import ContactService from '@/services/contactService'
import CompanyService from '@/services/companyService'
import CalendarService from '@/services/calendarService'
// content
import ContentModal from '@/components/content/ContentModal'
import ContentPanel from '@/components/content/ContentPanel'
// clients
import ContactCard from '@/components/contact/ContactCard'
import CompanyCard from '@/components/company/CompanyCard'
import ClientCardSkeleton from '@/components/client/ClientCardSkeleton'
// events
import CalendarViewer from '@/components/calendar/CalendarViewer'
// posts
import PostPanel from '@/components/post/PostPanel'
import PostModal from '@/components/post/PostModal'
// base
import CardCarousel from '@/components/base/CardCarousel'
import SectionPanel from '@/components/base/SectionPanel'
import SectionPanels from '@/components/base/SectionPanels'
import ShareModal from '@/components/base/ShareModal'
import shareMixin from '@/mixins/shareMixin'

export default {
  name: 'HomeView',

  components: {
    // content components
    ContentModal,
    ContentPanel,
    // client components
    ContactCard,
    CompanyCard,
    ClientCardSkeleton,
    // event components
    CalendarViewer,
    // post components
    PostPanel,
    PostModal,
    // base components
    CardCarousel,
    SectionPanel,
    SectionPanels,
    ShareModal
  },

  mixins: [shareMixin],

  data: function () {
    return {
      // page
      depth: 0,
      // contacts
      contacts: [],
      areContactsLoading: true,
      // companies
      companies: [],
      areCompaniesLoading: true,
      // events
      userEvents: [],
      areEventsLoading: true,
      // modals
      showSharingModal: false,
      showItemModal: false,
      showPostModal: false
    }
  },

  computed: {
    /* panel controls */

    showFavourites() {
      return (
        this.$store.state.menuStore.menuRoutes.includes('content') ||
        this.$store.state.menuStore.menuRoutes.includes('collection') ||
        this.$store.state.menuStore.menuRoutes.includes('channels')
      )
    },

    showPosts() {
      return (
        this.$store.state.menuStore.menuRoutes.includes('blog') ||
        this.$store.state.menuStore.menuRoutes.includes('channels')
      )
    },

    showCalendar() {
      return this.$store.state.menuStore.menuRoutes.includes('schedule')
    },

    showCompanies() {
      return this.$store.state.menuStore.menuRoutes.includes('companies')
    },

    showContacts() {
      return this.$store.state.menuStore.menuRoutes.includes('contacts')
    },

    /* calendar events */

    userEventsCount() {
      return this.userEvents.length
    },

    /* companies */

    userCompanyIds() {
      return this.$store.state.userStore.companies
    },

    userCompanies() {
      return this.companies.filter((company) => this.userCompanyIds.includes(company.id))
    },

    userCompaniesCount() {
      return this.userCompanies.length
    },

    /* contacts */

    userContactIds() {
      return this.$store.state.userStore.contacts
    },

    userContacts() {
      return this.contacts.filter((contact) => this.userContactIds.includes(contact.id))
    },

    userContactsCount() {
      return this.userContacts.length
    }
  },

  watch: {
    showItemModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    },

    showPostModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    }
  },

  created: async function () {
    /* retrieve all home page data in "parallel" */

    // ensure "content" store data is primed
    if (this.showFavourites) {
      this.fetchLibrary({ resync: false }).then(() => {
        this.isContentLoading = false
        this.contentResultSet = this.userFavourites
      })
    }

    // ensure post "store" is primed
    if (this.showPosts) {
      this.fetchPosts({ resync: false }).then(() => {
        this.arePostsLoading = false
      })
    }

    /* retrieve all non-store data */

    if (this.showContacts) {
      this.fetchContacts().then((contacts) => {
        this.contacts = contacts
        this.areContactsLoading = false
      })
    }

    if (this.showCompanies) {
      this.fetchCompanies().then((companies) => {
        this.companies = companies
        this.areCompaniesLoading = false
      })
    }

    if (this.showCalendar) {
      this.fetchCalendarToday().then((events) => {
        this.userEvents = events
        this.areEventsLoading = false
      })
    }
  },

  mounted: function () {
    const panels = this.$el.querySelectorAll('.c-panel')
    this.depth = panels.length || 0
  },

  methods: {
    // TODO: eventually, all data should come from a "store"
    ...mapActions('contentStore', ['fetchLibrary']),
    ...mapActions('postStore', ['fetchPosts']),

    /* calendar */

    async fetchCalendarToday() {
      const calendarService = new CalendarService()
      try {
        const d = new Date()
        const today = d.toJSON().slice(0, 10)
        const events = await calendarService.fetchCalendarEvents(today, today)
        return events
      } catch (error) {
        console.error(`[Home]: Unable to fetch calendar. ${error}`)
        return []
      }
    },

    /* contacts */

    async fetchContacts() {
      const contactService = new ContactService()
      try {
        const contacts = await contactService.fetchContacts()
        return contacts.data
      } catch (error) {
        console.error(`[Home]: Unable to fetch contacts. ${error}`)
        return []
      }
    },

    /* companies */

    async fetchCompanies() {
      const companyService = new CompanyService()
      try {
        const companies = await companyService.fetchCompanies()
        return companies.data
      } catch (error) {
        console.error(`[Home]: Unable to fetch companies. ${error}`)
        return []
      }
    },

    /* sharing */

    async shareItem(item) {
      this.item = item
      this.showSharingModal = await this.shareMixin_shareItem(item)
    },

    /* viewing */

    suspendScrolling(suspend) {
      // prevent scrolling of underlying content grid/tree
      // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
      if (suspend) {
        document.body.style.top = `-${window.scrollY}px` // capture before setting position to fixed
        document.body.style.position = 'fixed'
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
      // document.documentElement.addEventListener('touchmove', (e) => e.preventDefault())
    },

    viewItem(item) {
      // this.item = item
      // this.showItemModal = true
      this.$router.push({
        name: 'iplayer',
        params: { id: item.id }
      })
    },

    viewPost(post) {
      this.post = post
      this.showPostModal = true
    },

    viewParent(parentId) {
      const item = this.items?.find((item) => item.id === parentId)
      this.viewItem(item)
    }
  }
}
</script>

<style lang="css" scoped>
.c-home-view {
  background-color: var(--v-sheet-base);
  min-height: 100%;
}
</style>

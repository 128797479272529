<template>
  <div>
    <v-btn v-if="showInstallButton" fab small @click="install">
      <v-icon color="accent" large> mdi-tray-arrow-down </v-icon>
    </v-btn>
    <v-btn v-if="updateExists" fab small @click="refresh">
      <v-icon color="accent" large> mdi-cellphone-arrow-down </v-icon>
    </v-btn>
    <v-btn v-if="showReloadButton" fab small @click="reload">
      <v-icon color="accent" large> mdi-reload-alert </v-icon>
    </v-btn>
  </div>
</template>

<script>
class InstallError extends Error {
  constructor(message, options = {}) {
    super(message, options)
    this.name = 'InstallError'
  }
}

export default {
  name: 'AppInstall',

  components: {},

  data() {
    return {
      // installation
      showInstallButton: false,
      installEvent: null,

      // service worker refresh
      showReloadButton: false,

      // application updates
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },

  created() {
    // only supported by Chrome
    window.addEventListener('beforeinstallprompt', this.onBeforeInstall)

    // a new service worker is detected
    document.addEventListener('updateAvailable', this.onUpdateAvailable, { once: true })

    // refresh all open app tabs when a new service worker is installed
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', this.onControllerChange)
    }
  },

  methods: {
    /* app installation methods */

    onBeforeInstall(e) {
      e.preventDefault()
      this.installEvent = e // save the event
      this.showInstallButton = true
    },

    async install() {
      try {
        const outcome = await this.installEvent.userChoice()
        if (outcome === 'accepted') {
          console.info('[AppInstall]: Install accepted!')
        } else {
          console.info('[AppInstall]: Install denied!')
        }
        this.showInstallButton = false
      } catch (error) {
        this.$error(new InstallError(error.message))
      }
    },

    /* app (i.e. service worker) update methods */

    // new service worker detected
    onUpdateAvailable(e) {
      this.registration = e.detail
      this.updateExists = true
    },

    // ...update the service worker
    refresh() {
      this.updateExists = false

      // verify if an update is actually available
      if (!this.registration || !this.registration.waiting) {
        return
      }

      // give a green light to install the service worker
      this.registration.waiting.postMessage({ action: 'skipWaiting' })
    },

    // service worker was updated
    onControllerChange(_e) {
      if (this.refreshing) return
      this.showReloadButton = true
    },

    // ...reload the page to use it
    reload() {
      this.showReloadButton = false
      window.location.reload()
      this.refreshing = true
    }
  }
}
</script>

<style></style>

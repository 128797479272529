import Vue from 'vue'
import Vuex from 'vuex'
import pkg from '@@/package.json'

Vue.use(Vuex)

const initialState = {
  version: pkg.version,
  online: window.navigator.onLine
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.version = newState.version
    },

    /* specific mutations */

    setOnlineStatus(state, online) {
      state.online = online
    },

    setVersion(state, version) {
      state.version = version
    }
  },

  actions: {
    /* common actions */

    restoreState() {
      // appStore state is persisted, but not restored
    },

    /* specific actions */

    storeState({ state, commit }) {
      console.info('[appStore]: Version=', state.version)
      console.info('[appStore]: Initially ' + (state.online ? 'on' : 'off') + 'line.')
      commit('syncState', state)
    },

    updateOnlineStatus({ commit }, onlineStatus) {
      commit('setOnlineStatus', onlineStatus)
    },

    updateVersion({ commit }, version) {
      commit('setVersion', version)
    }
  }
}

import store from '@/store'

export const fetchChannels = async ({ filter }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  const queryString = filter ? `?filter=${encodeURIComponent(filter)}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/channels${queryString}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[channelService]:', error)
    return []
  }
}

export const fetchChannel = async (channelId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/channel/${channelId}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[channelService]:', error)
    return {}
  }
}

<template>
  <v-card v-if="revealBack" class="c-card--back c-card--reveal transition-fast-in-fast-out">
    <!-- header -->
    <v-list-item>
      <!-- title and subtitle -->
      <v-list-item-content class="align-self-start py-0">
        <v-card-title class="px-0 text-h5 text-left">
          {{ item.title }}
        </v-card-title>
        <v-card-subtitle class="px-0 pb-0 text-h6 text-left">
          <i>{{ item.subtitle }}</i>
        </v-card-subtitle>
      </v-list-item-content>
      <!-- close button -->
      <v-btn
        class="align-self-start mt-3"
        icon
        large
        color="accent"
        @click="$emit('click:reveal', false)"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-list-item>
    <!-- body -->
    <v-card-text class="text-left">
      <ClampedPortableText :blocks="summaryBlocks" />
    </v-card-text>
    <!-- parent document -->
    <v-card-actions class="justify-center">
      <v-btn v-if="hasParent" color="primary" large @click="$emit('view:parent')">
        {{ $t('content.action.viewArticle') }}
      </v-btn>
    </v-card-actions>

    <!-- authors -->
    <v-card-text v-if="hasAuthors">
      <v-subheader class="pl-0">
        {{ $t('content.authors').toUpperCase() }} / {{ $t('content.advisors').toUpperCase() }} ({{
          item.authors.length
        }})
      </v-subheader>
      <v-list class="pl-0">
        <v-list-item
          v-for="author in item.authors"
          :key="author.lastName"
          class="mb-3 pl-0 text-left"
        >
          <v-avatar v-if="author.avatar" class="mr-1">
            <img :lazy-src="require('@/assets/images/icons/avatar.png')" :src="author.avatar" />
          </v-avatar>
          <v-avatar v-else class="mr-1" color="purple">
            <span class="white--text text-h5">
              {{ getAuthorInitials(author) }}
            </span>
          </v-avatar>
          {{ formatAuthorName(author, true) }}
        </v-list-item>
      </v-list>
    </v-card-text>

    <!-- sponsor -->
    <v-card-text v-if="hasSponsor">
      <v-subheader class="pl-0">
        {{ $t('content.sponsor').toUpperCase() }}
      </v-subheader>
      <CompanyAvatar :sponsor="item.sponsor" />
    </v-card-text>
  </v-card>
</template>

<script>
import ClampedPortableText from '@/components/base/ClampedPortableText'
import CompanyAvatar from '@/components/base/CompanyAvatar'

export default {
  name: 'ContentCardBack',

  components: {
    ClampedPortableText,
    CompanyAvatar
  },

  model: {
    prop: 'reveal',
    event: 'click:reveal'
  },

  props: {
    reveal: {
      type: Boolean,
      required: true
    },

    item: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      revealBack: this.reveal
    }
  },

  computed: {
    summaryBlocks() {
      return this.item.summary
    },

    hasAuthors() {
      return this.item.authors?.length > 0 || false
    },

    hasParent() {
      return !!this.item.parent?.id
    },

    hasRelated() {
      return this.item.related?.length > 0
    },

    hasSponsor() {
      return !!this.item.sponsor
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    }
  },

  watch: {
    reveal: {
      immediate: false, // initial value captured via data()
      handler: function (newVal, _oldVal) {
        this.revealBack = newVal
      }
    }
  },

  methods: {
    formatAuthorName(author, isFull = true) {
      const suffixes = isFull ? author.suffixes?.join(', ') : ''
      const fullName = [author.honourific, author.firstName, author.lastName, suffixes].join(' ')
      return fullName
    },

    getAuthorInitials(author) {
      return author.firstName[0] + author.lastName[0]
    }
  }
}
</script>

<style lang="css" scoped>
.c-card--back {
  border-radius: 16px;
  height: 100%;
  overflow-y: auto;
}
.c-card--reveal {
  top: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>

<template>
  <transition name="fade">
    <v-img
      v-if="src"
      :key="src"
      ref="img"
      class="c-image"
      :class="{ 'c-cta': hasCTA }"
      :aspect-ratio="aspectRatio"
      contain
      :src="src"
      @click="$emit('select', ad)"
      @load="$emit('load', $refs.img.$el)"
    >
      <v-btn
        v-if="close"
        class="c-btn-close"
        color="rgba(0, 0, 0, 0.2)"
        fab
        @click.stop="$emit('close')"
      >
        <v-icon color="white" small> mdi-close </v-icon>
      </v-btn>
    </v-img>
  </transition>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'AdImage',

  components: {},

  mixins: [mobileMixin],

  props: {
    ad: {
      type: Object,
      required: true
    },

    aspectRatio: {
      type: String,
      required: false,
      default: ''
    },

    close: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {
      observer: null
    }
  },

  computed: {
    hasCTA() {
      return this.ad.adItem.cta?.href
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    orientation() {
      return this.mobileMixin_orientation
    },

    src() {
      const url = this.isReallyMobile
        ? this.orientation === 'portrait'
          ? this.ad.adItem.mobilePortraitImage || ''
          : this.ad.adItem.mobileLandscapeImage || ''
        : this.ad.adItem.image

      return this.ad.adItem.asset || url || this.ad.adItem.image
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-close {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  width: 20px;
}
.c-btn-close i:hover {
  transform: scale(1.25);
}

.c-cta {
  cursor: pointer;
}

.c-image {
  background-color: var(--v-background-base);
  width: 100%;
  height: auto;
}
</style>

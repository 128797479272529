// ¯\_(ツ)_/¯

import Vue from 'vue'
import App from '@/App'

// import core css assets
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.min.css' // https://materialdesignicons.com
import '@fortawesome/fontawesome-free/css/fontawesome.min.css' // https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free

// import filters
import registerFilters from '@/filters'

// import plugins
import registerPlugins from '@/plugins/registerPlugins.js'

// import Vue platform extensions
import registerServiceWorker from '@/registerServiceWorker.js'
import router from '@/router' // returns new VueRouter
import store from '@/store' // returns new Vuex

// import config
import { getFeature } from '@/config/featureConfig'

// startup message
console.info(`Lumenii is starting...`)

// register Vue plugins and filters
registerPlugins({ router, store })
registerFilters()

// register PWA service worker
if (getFeature('pwa')) registerServiceWorker()

// setup Vue configuration
Vue.config.productionTip = false
Vue.config.ignoredElements = ['model-viewer']

// create the Vue instance
const app = new Vue({
  i18n: Vue.prototype.$_i18n,
  store,
  router,
  vuetify: Vue.prototype.$vuetify,
  render: (h) => h(App),
  renderError: (h, err) => h('pre', { style: { color: 'red' } }, err.stack)
})

app.$mount('#app')

if (typeof window !== 'undefined') {
  window.$lumenii = {
    vm: app,
    locale: Vue.prototype.$_i18n.locale,
    store: store
  }
}

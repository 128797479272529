<template>
  <div class="c-body">
    <v-app id="app" class="c-app">
      <CircularProgress
        v-if="loading"
        :logo="logo"
        :message="$t('message.initializing')"
      />
      <component :is="layout" v-else />
    </v-app>
    <div v-if="portlet" class="c-popup">
      <MeetingWindow
        ref="meetingWindow"
        v-model="portlet"
        :jwt="jwt"
        :room="room"
        :subject="subject"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CircularProgress from '@/components/base/CircularProgress'
import MeetingWindow from '@/components/meetings/MeetingWindow'
import exitMixin from '@/mixins/exitMixin.js'
import layoutMixin from '@/mixins/layoutMixin.js'

import OrgService from '@/services/orgService'
import localhostConfig from '@/config/localhostConfig.json'

const defaultLayout = 'PageLayout' // avoids premature rendering of app layout elements

class TenantError extends Error {
  constructor(message, options = {}) {
    super(message, options)
    this.name = 'TenantError'
  }
}

export default {
  name: 'App',

  components: {
    CircularProgress,
    MeetingWindow
  },

  mixins: [exitMixin, layoutMixin],

  data: function () {
    return {
      // app data
      loading: true, // avoids a forced reflow
      orgConfig: null,
      useLocal: false,

      // meeting data
      portlet: false,
      jwt: null,
      room: '',
      subject: ''
    }
  },

  computed: {
    /* app settings */

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    layout() {
      return this.$route.meta.layout || defaultLayout
    },

    /* tenant/org settings */

    tenantKey() {
      return this.$store.state.tenantStore.tenantKey
    },

    tenant() {
      return this.$store.state.tenantStore.tenant
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    orgKey() {
      return this.$store.state.tenantStore.orgKey
    },

    logo() {
      // since org is being loaded, assume a naming convention
      return `/img/logos/${this.tenantKey}-logo.png`
    },

    /* content settings */
    collections() {
      return this.$store.state.contentStore.collections
    }
  },

  beforeCreate: async function () {
    // setup all the store modules from local storage
    // (use dispatch since methods are not initialized yet)
    await this.$store.dispatch('restoreState', { vm: this }, { root: true })
  },

  created: async function () {
    await this.initializeTenant()
  },

  mounted: async function () {
    this.$appConfig.historyStart = window.history.length
    this.addBusListeners()
    this.addEventListeners()
    this.$nextTick(this.setDimensions)
  },

  beforeDestroy: function () {
    this.removeEventListeners()
  },

  methods: {
    ...mapActions(['restoreState', 'reflectOrg', 'reflectUser']),
    ...mapActions('appStore', ['updateOnlineStatus']),
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('channelStore', ['fetchChannels']),
    ...mapActions('contentStore', [
      'fetchLibrary',
      'fetchCollectionItems',
      'fetchCollectionItemsSet'
    ]),

    /* manage events */

    addBusListeners() {
      // exit the app
      this.$bus.$on('exit', () => {
        this.exitMixin_exit()
      })

      // show the portal window
      this.$bus.$on('meeting:show', (meeting) => {
        this.jwt = meeting.jwt
        this.room = meeting.room
        this.subject = meeting.subject
        this.portlet = true
        console.debug('[App]: meeting:show event received. Meeting=', meeting)
      })

      // reset when the portal window is blocked
      this.$bus.$on('meeting:reset', () => {
        this.jwt = null
        this.room = null
        this.subject = null
        this.portlet = false
      })

      // this event occurs from the Vue instance within the portal window
      this.$bus.$on('meeting:hide', () => {
        self.close()
      })
    },

    addEventListeners() {
      window.addEventListener('popstate', (s) => console.log('[App]: popstate=', s))
      window.addEventListener('resize', this.setDimensions)
      window.addEventListener('online', this.changeStatus)
      window.addEventListener('offline', this.changeStatus)
    },

    removeEventListeners() {
      window.removeEventListener('resize', this.setDimensions)
      window.removeEventListener('online', this.changeStatus)
      window.removeEventListener('offline', this.changeStatus)
    },

    /* setup tenant */

    async initializeTenant() {
      try {
        // retrieve the tenant record
        const tenant = await this.$store.dispatch('tenantStore/fetchTenant', { resync: true })

        // initialize the authentication/authorization services
        await this.$auth.initialize(tenant)

        // fetch and configure the current org (for this tenant)
        await this.setupOrg(this.orgKey)

        // prime the content/post/channel caches
        await this.primeCaches()
      } catch (error) {
        this.loading = false
        this.$auth.cancel(false)
        this.$error(new TenantError(error.message), { tenant: this.tenantKey })
      }
    },

    async primeCaches() {
      // force reloading of data
      const resync = true

      // load the content library
      this.fetchLibrary({ resync })
      /*
      this.collections.forEach((collection) =>
         this.fetchCollectionItems({ key: collection.key, resync })
      */
      // reload all the known collections (previously cached by the contentStore)
      const keys = this.collections.map((collection) => collection.key)
      this.fetchCollectionItemsSet({ keys, resync })

      this.fetchChannels({ resync })
      this.fetchPosts({ resync })
    },

    async setupOrg(orgKey) {
      this.loading = true

      const orgService = new OrgService()

      try {
        const orgConfig = this.useLocal ? localhostConfig : await orgService.fetchOrgConfig(orgKey)
        this.reflectOrg({ orgConfig, vm: this.$root })
        return orgConfig
      } catch (error) {
        // rely on localStorage values (as loaded in beforeCreate())
        console.error(`[App]: Unable to retrieve org. ${error}.`)
      } finally {
        this.loading = false
      }
    },

    /* capture context */

    changeStatus() {
      const onlineStatus = window.navigator.onLine
      this.updateOnlineStatus(onlineStatus)
    },

    documentHeight() {
      return Math.max(
        document.documentElement.clientHeight,
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight
      )
    },

    setDimensions() {
      const root = document.documentElement

      /* retrieve viewport dimensions */
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      /* set viewport dimensions */
      root.style.setProperty('--c-viewport-width', `${vw}px`)
      root.style.setProperty('--c-viewport-height', `${vh}px`)
      root.style.setProperty('--vh', `${vh / 100}px`)

      /* set scrollbar dimensions */
      const scrollbarSize = this.layoutMixin_calculateScrollbarSize(root)
      root.style.setProperty('--c-scrollbar-height', `${scrollbarSize.height}px`)
      root.style.setProperty('--c-scrollbar-width', `${scrollbarSize.width}px`)
    }
  }
}
</script>

<style lang="css" scoped>
.c-app {
  min-height: 100%;
  width: 100%;
}

.c-popup {
  height: 100%;
  z-index: 1000;
}

.c-body {
  position: relative;
  width: 100%;
}
</style>

<!-- Global Styles -->
<style lang="css">
html,
body {
  padding: 0;
  margin: 0;
  min-height: var(--c-viewport-height);
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--v-background-base);
}

html {
  overflow-y: auto !important;
}
</style>

<!-- Overrides -->
<style lang="css">
@import './assets/styles/alerts.css';
@import './assets/styles/buttons.css';
@import './assets/styles/globals.css';
@import './assets/styles/vuetify.css';

.v-application--wrap {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}
</style>

export default {
  data: function () {
    const portraitQuery = window.matchMedia('(orientation: portrait)')
    return {
      mobileMixin_orientation: portraitQuery.matches ? 'portrait' : 'landscape',
      mobileMixin_portraitQuery: portraitQuery
    }
  },

  computed: {
    mobileMixin_isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    mobileMixin_isReallyMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    },

    mobileMixin_isAndroid() {
      return /Android/i.test(window.navigator.userAgent)
    },

    mobileMixin_isiOS() {
      return /iPhone|iPad|iPod/i.test(window.navigator.userAgent)
    },

    mobileMixin_browserName() {
      const test = (regexp) => regexp.test(window.navigator.userAgent)
      switch (true) {
        case test(/edg/i):
          return 'Edge'
        case test(/trident/i):
          return 'IE'
        case test(/firefox|fxios/i):
          return 'Firefox'
        case test(/opr\//i):
          return 'Opera'
        case test(/ucbrowser/i):
          return 'UC Browser'
        case test(/samsungbrowser/i):
          return 'Samsung Browser'
        case test(/chrome|chromium|crios/i):
          return 'Chrome'
        case test(/safari/i):
          return 'Safari'
        default:
          return 'Other'
      }
    },

    mobileMixin_isLandscape() {
      return window.innerWidth > window.innerHeight
    },

    mobileMixin_isPortrait() {
      return window.innerWidth < window.innerHeight
    }
  },

  mounted: function () {
    this.mobileMixin_portraitQuery.addEventListener('change', this.mobileMixin_detectOrientation)
  },

  beforeDestroy: function () {
    this.mobileMixin_portraitQuery.removeEventListener('change', this.mobileMixin_detectOrientation)
  },

  methods: {
    mobileMixin_detectOrientation() {
      this.mobileMixin_portraitQuery.matches
        ? (this.mobileMixin_orientation = 'portrait')
        : (this.mobileMixin_orientation = 'landscape')
    }
  }
}

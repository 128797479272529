// MSAL Reference
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html
import { LogLevel } from '@azure/msal-browser'
import assert from 'assert'

// Active Directory B2C Settings
const AAD_AUTHORITY_DOMAIN = process.env.VUE_APP_AZURE_ADB2C_AUTHORITY_DOMAIN // e.g. lumenii.b2clogin.con
const AAD_TENANT = process.env.VUE_APP_AZURE_ADB2C_TENANT // e.g. lumenii.onmicrosoft.com OR custom domain
const AAD_POLICY = process.env.VUE_APP_AZURE_ADB2C_POLICY // e.g. B2C_1_SignIn
const AAD_CLIENT_ID = process.env.VUE_APP_AZURE_ADB2C_CLIENT_ID // use guid of app registered in Azure B2C

assert(AAD_AUTHORITY_DOMAIN, '[msalConfig]: Missing env VUE_APP_AZURE_ADB2C_AUTHORITY_DOMAIN')
assert(AAD_TENANT, '[msalConfig]: Missing env VUE_APP_AZURE_ADB2C_TENANT')
assert(AAD_POLICY, '[msalConfig]: Missing env VUE_APP_AZURE_ADB2C_POLICY')
assert(AAD_CLIENT_ID, '[msalConfig]: Missing env VUE_APP_AZURE_ADB2C_CLIENT_ID')

export const policies = [
  'B2C_1_SignUpAndSignIn',
  // 'B2C_1_SignInUsingIdP',
  'B2C_1_SignUp',
  'B2C_1_SignIn',
  // 'B2C_1_SignInOrSignUp',
  // 'B2C_1_SignInAndSignUp',
  'B2C_1_ResetPassword',
  'B2C_1_EditProfile'
]

const authority = (policy) => `${AAD_AUTHORITY_DOMAIN}/${AAD_TENANT}/${policy}`

export const oidc = {
  metadata1: `https://lumenii.b2clogin.com/tfp/lumenii.onmicrosoft.com/B2C_1_SignIn/v2.0/.well-known/openid-configuration`,
  metadata2: `https://lumenii.b2clogin.com/lumenii.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_SignIn`
}

function loggerCallback(logLevel, message, containsPII) {
  if (containsPII) {
    return
  }
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message)
      return
    case LogLevel.Info:
      console.info(message)
      return
    case LogLevel.Verbose:
      console.debug(message)
      return
    case LogLevel.Warning:
      console.warn(message)
  }
}

export default {
  auth: {
    clientId: AAD_CLIENT_ID,
    policy: AAD_POLICY, // just put this in here for convenience (not used by msal)
    authority: authority(AAD_POLICY),
    knownAuthorities: [AAD_AUTHORITY_DOMAIN], // policies.map((policy) => authority(policy)),
    cloudDiscoveryMetadata: '',
    redirectUri: `${window.location.origin}/`, // after login, navigate back to root of SPA
    postLogoutRedirectUri: `${authority(AAD_POLICY)}/oauth2/v2.0/logout?post_logout_redirect_uri=${
      window.location.origin
    }/close`,
    navigateToLoginRequestUrl: false, // "false" lets the app handle redirects after login
    clientCapabilities: ['CP1']
  },
  cache: {
    cacheLocation: 'localStorage', // enables session to be reused across tabs
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: loggerCallback,
      piiLoggingEnabled: true,
      logLevel: LogLevel.Warning
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
    allowRedirectInIframe: true // required for single sign out via OIDC 'front-channel' logout
  }
}

<template>
  <v-navigation-drawer
    app
    class="c-drawer"
    disable-resize-watcher
    disable-route-watcher
    fixed
    hide-overlay
    left
    :value="open"
    width="calc(max(256px, 20%))"
  >
    <div class="c-container pa-4">
      <!-- ad -->
      <AdBlock
        v-if="showAds"
        v-model="showAds"
        class="c-ads mb-4"
        :max="1"
        stack
        :outline="false"
        zone="drawer"
      />

      <!-- details -->
      <ContentDetails v-if="item" :item="item" />

      <!-- assistance -->
      <v-card v-if="hasGuidance || hasCorpus" class="c-assistance mx-auto mt-4" tile>
        <v-list rounded>
          <v-subheader>
            {{ $t('content.assistance.title').toUpperCase() }}
          </v-subheader>

          <!-- chatbot -->
          <v-list-item v-if="hasCorpus" class="my-0">
            <v-list-item-avatar>
              <v-img class="c-avatar" :src="avatarImage" contain />
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showChatbot"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.askMelissa')"
                :ripple="false"
              />
            </v-list-item-content>
          </v-list-item>

          <!-- guidance -->
          <v-list-item v-if="hasGuidance" class="my-0">
            <v-list-item-icon class="mr-4">
              <v-icon size="40" large> mdi-compass </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <v-switch
                v-model="showGuidance"
                class="c-switch"
                color="primary"
                dense
                :label="$t('content.assistance.guideMe')"
                :ripple="false"
                @change="$bus.$emit('select:guidance', showGuidance)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- scenes and animations -->
      <v-card v-if="hasScenes" class="c-scenes mx-auto mt-4" tile>
        <SceneList
          v-if="hasMoreThanOneScene"
          v-model="selectedSceneId"
          class="mb-4"
          :scenes="scenes"
          @select="selectScene($event)"
          @sync="syncScene($event)"
        />
        <AnimationList
          v-if="hasAnimations"
          v-model="selectedAnimationIds"
          :animations="animations"
          @select="selectAnimation($event)"
          @sync="syncAnimations($event)"
        />
      </v-card>

      <!-- chatbot -->
      <div v-if="hasCorpus" class="c-chatbot flex-column align-start justify-start mt-4">
        <ChatbotBase v-show="showChatbot" ref="chatbot" :corpus="corpus" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import AdBlock from '@/components/ad/AdBlock'
import AnimationList from '@/players/ModelPlayer/components/AnimationList'
import ChatbotBase from '@/players/ChatbotPlayer/ChatbotBase'
import ContentDetails from '@/components/content/ContentDetails'
import SceneList from '@/players/ModelPlayer/components/SceneList'

export default {
  name: 'PlayerDrawer',

  components: {
    AdBlock,
    AnimationList,
    ChatbotBase,
    ContentDetails,
    SceneList
  },

  model: {
    prop: 'open',
    event: 'toggled'
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    },

    context: {
      type: Object,
      required: false,
      default: () => {}
    },

    open: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      showChatbot: true,
      showGuidance: false,
      scenes: [],
      selectedSceneId: '',
      selectedAnimationIds: [],
      showAds: true
    }
  },

  computed: {
    /* context */

    params() {
      return this.item?.params || []
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* corpus */

    corpus() {
      return this.params?.find((param) => param.name === 'corpus')?.value || ''
    },

    hasCorpus() {
      return !!this.corpus
    },

    /* guidance */

    guidance() {
      return (
        this.params
          ?.find((param) => param.name === 'guidance')
          ?.value?.toLowerCase()
          ?.trim() === 'true'
      )
    },

    hasGuidance() {
      return this.guidance
    },

    /* animations & scenes */

    animations() {
      return this.scenes[this.selectedSceneId]?.[this.locale]?.animations || []
    },

    hasAnimations() {
      return this.hasScenes && this.animations.length > 0
    },

    hasScenes() {
      return this.scenes.filter((scene) => scene.id).length > 0
    },

    hasMoreThanOneScene() {
      return this.scenes.filter((scene) => scene.id).length > 1
    }
  },

  watch: {
    context: {
      immediate: false, // context is resolved after mounting
      handler: function (newContext, _oldContext) {
        this.scenes = newContext.scenes || []
        this.selectedSceneId = newContext.sceneId || ''
      }
    }
  },

  mounted: function () {
    // listen to the SceneSelector / AnimationSelector
    this.$bus.$on('sync:selector', ({ selectedSceneId, selectedAnimationIds }) => {
      if (selectedSceneId) {
        this.selectedSceneId = selectedSceneId
      }
      if (selectedAnimationIds) {
        this.selectedAnimationIds = [...selectedAnimationIds]
      }
    })
  },

  beforeDestroy: function () {
    this.$bus.$off('sync:selector')
  },

  methods: {
    selectScene(_sceneId) {
      // clear all aninmations
      this.selectedAnimationIds = []

      // notify other components
      // this.$bus.$emit('select:scene', sceneId)
    },

    syncScene(selectedSceneId) {
      // notify the SceneSelector
      this.$bus.$emit('sync:drawer', {
        selectedSceneId,
        selectedAnimationIds: []
      })
    },

    selectAnimation(_animationId) {
      // notify other components
      // this.$bus.$emit('select:animation', animationId)
    },

    syncAnimations(selectedAnimationIds) {
      // notify the AnimationSelector
      this.$bus.$emit('sync:drawer', { selectedAnimationIds })
    },

    clearChatHistory() {
      this.$refs.chatbot.clearChatHistory()
    }
  }
}
</script>

<style lang="css" scoped>
.c-drawer {
  background-color: var(--v-background-base);
  border-right: solid 1px;
  top: var(--c-player-bar-height, 56px) !important;
  padding-bottom: var(--c-player-bar-height, 56px);
  z-index: 2;
}

.c-container p {
  font-size: 1.25rem;
}

.v-list-item {
  margin-bottom: 16px;
}

/* chatbot */

.c-chatbot {
  width: 100%;
  height: 100%;
}
.c-chatbot .c-avatar {
  width: 128px;
  height: 128px;
  margin: auto;
}

/* scenes */
.c-list-item--active {
  background-color: var(--v-accent-base);
}

.c-scenes .c-header {
  font-weight: 600;
}

.c-scenes .c-subheader {
  color: var(--v-accent-base) !important;
  font-style: italic;
}
</style>

<template>
  <v-footer
    app
    class="c-footer"
    :style="leftStyle"
    fixed
    height="48"
    inset
  >
    <v-container class="text-center pa-0">
      <v-row class="mx-auto my-0" align="center" justify="space-around">
        <v-col class="pa-0">
          <v-btn class="ma-auto" text rounded>
            {{ $t('footer.contact') }}
          </v-btn>
        </v-col>
        <v-col class="pa-0">
          <p class="ma-auto d-inline">
            &copy;{{ new Date().getFullYear() }}&nbsp;
            <strong>
              <a :href="orgLink" target="_blank">{{ orgName }}</a>
            </strong>
          </p>
        </v-col>
        <v-col class="pa-0">
          <v-btn class="ma-auto" text rounded>
            {{ $t('footer.problem') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',

  components: {},

  data: function () {
    return {
      orgName: this.$store.state.orgStore.orgName,
      orgLogo: this.$store.state.orgStore.orgLogo,
      orgLink: this.$store.state.orgStore.orgLink
    }
  },

  computed: {
    leftStyle() {
      return {
        left: `${this.$appConfig.leftMargin}px !important`
      }
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-footer {
  border-color: var(--v-primary-base) !important;
  border-top: solid 2px;
}
</style>

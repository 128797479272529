import { PublicClientApplication } from '@azure/msal-browser'
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser'
import msalConfig from '@/services/agents/msal/msalConfig.js'
import { AuthenticationError, PopupError, TokenError } from '@/config/authConfig.js'

class MsalAgent {
  constructor() {
    // authentication client
    this.msalClient = null
    this.loading = true

    // authentication state
    this.account = null
    this.authenticated = false
    this.user = null
    this.domainHint = ''

    // tokens (all are JWT-based)
    this.accessToken = null // from IdP (to call /userinfo endpoint)
    this.idToken = null // from IdP
  }

  async initialize(dynamicConfig) {
    const config = {
      ...msalConfig,
      ...dynamicConfig
    }

    this.domainHint = dynamicConfig.authorizationParams?.domainHint

    this.msalClient = new PublicClientApplication(config)
  }

  /*
   * Response Handling Methods
   */

  async handleRedirect() {
    try {
      const redirectResponse = await this.msalClient.handleRedirectPromise()
      if (redirectResponse) {
        console.debug('[msalAgent]: redirect response exists!')
        const { state } = this.parseResponse(redirectResponse)
        return state
      }

      // rather than forcing a login flow, check for existing sessions in the cache
      const account = this.findAccount()
      account ? this.setAccount(account) : this.setAccount(null)
      this.authenticated = !!account
      console.debug('[msalAgent]: Account=', account)
      return null
    } catch (e) {
      console.error('[msalAgent]: handleRedirect error.', e)
      this.authenticated = false
      this.setAccount(null)
      // throw e
      return null
    }
  }

  parseResponse(response) {
    // Response
    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#authenticationresult
    console.debug('[msalAgent]: post login response=', response)
    if (response) {
      // initialize account and tokens
      this.setAccount(response.account)
      this.accessToken = response.accessToken
      this.idToken = response.idToken
      this.authenticated = true

      // look for an application state
      if (response.state) {
        this.state = JSON.parse(decodeURIComponent(response.state))
      } else {
        this.state = {}
      }

      return {
        account: response.account,
        state: this.state
      }
    } else {
      return {}
    }
  }

  /*
   * Login/Logout Methods
   */

  constructLoginRequest(options) {
    const domainHint = options.domainHint || ''
    const loginHint = options.loginHint || ''
    return {
      prompt: options.prompt || 'select_account',
      domainHint: domainHint,
      loginHint: loginHint,
      scopes: options.scopes || ['openid'],
      state: encodeURIComponent(JSON.stringify(options.state)) || '',
      // passed directly onto /authorize URL
      extraQueryParameters: {
        domain_hint: domainHint,
        login_hint: loginHint,
        ui_locales: options.locale,
        ...options.extraQueryParameters
      }
      // override only used if navigateToLoginRequestUrl is true...
      // redirectStartPage: '/'
      // callback passed in the target URL; returning false stops the navigation...
      // onRedirectNavigate: this.redirectNavigation(targetURL)
    }
  }

  async loginWithPopup(options) {
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }
    // popupErrorCodes = ['popup_window_error', 'empty_window_error', 'interaction_in_progress']

    try {
      // clear out msal session items in case there was a previous fa
      // this.msalClient.browserStorage?.clear()

      // show login popup
      const loginResponse = await this.msalClient.loginPopup(loginRequest)
      console.debug('[msalAgent]: after popup, response=', loginResponse)
      const { account } = this.parseResponse(loginResponse)
      if (!account) throw new AuthenticationError('No account after login.')
      return false // do not retry
    } catch (e) {
      // handle case where user cancels authentication
      if (e.errorCode === 'access_denied' && e.errorMessage.startsWith('AADB2C90091')) {
        return true // try again
      }

      console.error('[msalAgent]: loginPopup errorCode=', e.errorCode)
      sessionStorage.removeItem('msal.interaction.status')

      if (e instanceof BrowserAuthError) {
        if (e.errorCode === 'interaction_in_progress') throw new AuthenticationError(e)
        if (e.errorCode === 'user_cancelled') return true // try again
        else throw new PopupError(e)
      }

      throw new AuthenticationError(e)
    }
  }

  async loginWithRedirect(options) {
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }

    try {
      console.debug('[msalAgent]: loginRequest=', loginRequest)
      await this.msalClient.loginRedirect(loginRequest)
      console.warn('[msalAgent]: Unexpectedly returning from loginRedirect!')
      return false
    } catch (e) {
      // handle case where user cancels authentication
      if (e.errorCode === 'access_denied' && e.errorMessage.startsWith('AADB2C90091')) {
        return true // try again
      }

      console.error('[msalAgent]: loginRedirect error.', e)
      throw new AuthenticationError(e)
    }
  }

  async loginWithSSO(options) {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/VanillaJSTestApp2.0/app/ssoSilent/auth.js
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }

    try {
      console.log('[msalAgent]: Attempting silent SSO...')
      loginRequest.prompt = 'none'
      const loginResponse = await this.msalClient.ssoSilent(loginRequest)
      this.parseResponse(loginResponse)
      return false // do not retry
    } catch (e) {
      // handle case where user cancels authentication
      if (e.errorCode === 'access_denied' && e.errorMessage.startsWith('AADB2C90091')) {
        return true // try again
      }

      if (e instanceof InteractionRequiredAuthError) {
        console.debug('[msalAgent]: Interaction required...')
        loginRequest.prompt = 'select_account'
        const retry = await this.loginWithPopup(loginRequest)
        return retry
      } else {
        console.error('[msalAgent]: SSO login error.', e)
        throw new AuthenticationError(e)
      }
    }
  }

  async logout(options) {
    try {
      const logoutRequest = {
        // idTokenHint: this.idToken,
        // promptless logout:
        // account: this.account,
        // logoutHint: this.getUserId(),
        //
        // logoutRedirect:
        // onRedirectNavigate: (url) => onLogoutRedirect(url)
        // postLogoutRedirectUri: options?.redirectURI || '/close'
        //
        // logoutPopup:
        // postLogoutRedirectUri: `${window.location.origin}/splash?notice=logout`, // opened in popup window
        mainWindowRedirectUri: options?.redirectURI || `${window.location.origin}/close`
      }
      console.debug('[msalAgent]: Logout request=', logoutRequest)
      const logoutResponse = await this.msalClient.logoutPopup(logoutRequest)
      console.debug('[msalAgent]: Logout response=', logoutResponse)
    } catch (e) {
      console.error('[msalAgent]: Logout error.', e)
      // FIXME: which exception should be returned?
      throw e
    } finally {
      this.authenticated = false
      this.setAccount(null)
      this.clearCache()
    }
  }

  clearCache() {
    // expire all cookies
    const cookies = document.cookie.split(';')
    console.warn('[msalAgent]: cookies=', cookies)
    cookies.forEach(
      (cookie) => (document.cookie = cookie + '=; expires=' + new Date(0).toUTCString())
    )
    // clear session storage
    sessionStorage.removeItem('msal.interaction.status')
  }

  onLogoutRedirect(url) {
    console.debug('[msalAgent]: onLogoutRedirect url=', url)
    this.authenticated = false
    this.setAccount(null)
    this.clearCache()
    return true // 'false' restricts to being just a local logout
  }

  isAuthenticated() {
    return this.authenticated
  }

  /*
   * Account Methods
   */

  setAccount(account) {
    this.account = account
    this.msalClient.setActiveAccount(this.account)
  }

  findAccount() {
    const currentAccounts = this.msalClient.getAllAccounts()
    if (!currentAccounts || currentAccounts.length < 1) {
      console.info('[msalAgent]: No active accounts!')
      return null
    } else if (currentAccounts.length === 1) {
      console.info('[msalAgent]: Active account detected!')
      console.info('[msalAgent]: Selecting', currentAccounts[0].username)
      return currentAccounts[0]
    } else if (currentAccounts.length > 1) {
      // TODO: add account chooser code (only required if one allows multiple logins per session)
      console.info('[msalAgent]: Multiple accounts detected!')
      console.warn('[msalAgent]: Accounts=', currentAccounts)
      // FIXME: what's the best account selection logic...or should we force a login to choose?
      // const selectedAccount = this.selectAccount(currentAccounts)
      const selectedAccount = currentAccounts[currentAccounts.length - 1]
      // this.loginWithRedirect({ domainHint: this.domainHint })
      console.info('[msalAgent]: Selecting', selectedAccount.username)
      return selectedAccount
    }
  }

  selectAccount(currentAccounts) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId.toUpperCase().includes(msalConfig.auth.policy.toUpperCase()) &&
        account.idTokenClaims.iss.toUpperCase().includes(msalConfig.auth.authority.toUpperCase()) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId
    )

    // localAccountId identifies the entity for which the token asserts information.
    if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
      // All accounts belong to the same user
      return accounts[0]
    } else {
      // Multiple users detected. Logout all to be safe.
      this.logout({})
      return null
    }
  }

  /*
   * User Methods
   */

  getUserId() {
    // userId is case insensitive (in Lumenii and ActiveDirectory)
    return this.account?.username?.toLowerCase()
  }

  getUserEmail() {
    return this.account?.username
  }

  getUserDisplayName() {
    return this.account?.name
  }

  getUserUniqueId() {
    return this.account?.localAccountId
  }

  getUserPicture() {
    // FIXME: probably won't work
    return this.account?.picture
  }

  getUserSubject() {
    return this.account?.idTokenClaims.sub
  }

  /*
   * Token Methods
   */

  async getIdTokenClaims() {
    return this.account?.idTokenClaims
  }

  async acquireTokens(options) {
    // TODO: intermittent error: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1156
    const tokenRequest = {
      account: this.account,
      scopes: options.scopes || ['openid', 'profile', 'mail', 'phone'],
      forceRefresh: false, // false -> get tokens from cache
      // FIXME: block_iframe_reload error...need to redirect to a non-MSAL route
      redirectUri: `${window.location.origin}/splash`
    }

    try {
      // first, attempt to retrieve tokens silently
      const response = await this.msalClient.acquireTokenSilent(tokenRequest)
      if (response) {
        this.accessToken = response.accessToken
        return this.accessToken
      } else {
        const errorMessage = '[msalAgent]: acquireTokenSilent returned null response.'
        console.error(errorMessage)
        throw new TokenError(errorMessage)
      }
    } catch (e) {
      // next issue a popup - because tokens are expired, password was changed, or account policies were updated
      if (e instanceof InteractionRequiredAuthError) {
        try {
          const response = await this.msalClient.acquireTokenPopup(tokenRequest)
          this.accessToken = response.accessToken
          return this.accessToken
        } catch (e) {
          console.error(`[msalAgent]: Unable to acquire token (code: ${e.errorCode}). ${e}`)
          // finally, if popups are blocked, try a redirect
          if (
            e instanceof BrowserAuthError ||
            e.errorCode === 'popup_window_error' ||
            e.errorCode === 'empty_window_error'
          ) {
            await this.msalClient.acquireTokenRedirect(tokenRequest)
          } else {
            console.error('[msalAgent]: acquireTokenPopup error=', e)
            throw new TokenError(e)
          }
        }
      } else {
        console.error('[msalAgent]: acquireTokenSilent error=', e)
        throw new TokenError(e)
      }
    }
  }
}

export const createMsalAgent = async (options) => {
  const agent = new MsalAgent()
  await agent.initialize(options)
  return agent
}

<!-- eslint-disable vue/attribute-hyphenation -->
<!-- (React components use camelCase attributes) -->
<template>
  <div
    class="c-chatbot-player d-flex flex-wrap flex-row align-start justify-center"
    :class="[isReady ? 'c-splitscreen' : 'c-fullscreen']"
  >
    <div class="c-left">
      <v-img
        class="c-avatar"
        :src="avatarImage"
        contain
      />
    </div>
    <div class="c-right">
      <ChatbotBase
        class="c-chatbot"
        :corpus="corpus"
        @ready="isReady = true"
        @error:loading="$emit('error:loading')"
      />
    </div>
  </div>
</template>

<script>
import ChatbotBase from './ChatbotBase'

export default {
  name: 'ChatbotPlayer',

  components: {
    ChatbotBase
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      isReady: false
    }
  },

  computed: {
    corpus() {
      return this.content.params?.find((param) => param.name === 'corpus')?.value
    }
  }
}
</script>

<style lang="css" scoped>
/* fullscreen layout (before chatbot is ready) */
.c-fullscreen {
  padding-top: 16px;
  width: 100%;
  height: 100%;
}
.c-fullscreen .c-avatar {
  width: 90%;
  margin: 0 auto;
  max-height: calc(100% - var(--c-player-bar-height, 56px) - 16px);
  max-height: calc(100% - var(--c-player-bar-height) - 16px);
}
.c-fullscreen .c-left {
  width: 100%;
}
.c-fullscreen .c-right {
  width: 0%;
}

/* splitscreen layout (after chatbot is ready) */
.c-splitscreen {
  padding-top: 16px;
}
.c-splitscreen .c-avatar {
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  margin-bottom: 16px;
}
.c-splitscreen .c-left {
  width: 25%;
}
.c-splitscreen .c-right {
  width: 75%;
  padding: 5%;
  padding-top: 16px;
}

.c-splitscreen .c-chatbot {
  padding-top: 0px;
}
.c-splitscreen .c-chatbot > div[role='complementary'] {
  max-width: 1200px !important;
}
</style>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-sheet v-if="header" height="64">
      <v-toolbar color="secondary" dense flat>
        <v-btn class="mr-4 text-md-h6 text-lg-h5 text-xl-h5" outlined @click="setToday">
          {{ $t('calendar.today') }}
        </v-btn>
        <v-btn class="mr-2" fab text outlined @click="prev">
          <v-icon x-large> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn class="mr-4" fab text outlined @click="next">
          <v-icon x-large> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" class="text-md-h5 text-lg-h4 text-xl-h4">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer />
        <CalendarModeSwitcher v-if="$vuetify.breakpoint.mdAndUp" />
        <v-spacer />
        <v-menu bottom right>
          <template #activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <span class="text-md-h6 text-lg-h5 text-xl-h5">
                {{ typeToLabel[type] }}
              </span>
              <v-icon x-large right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>{{ $t('calendar.day') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>{{ $t('calendar.week') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>
                {{ $t('calendar.month') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title> 4 {{ $t('calendar.days') }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColour"
        :event-name="translateEventName"
        :event-overlap-mode="overlapMode"
        :type="type"
        :weekdays="weekday"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="getEvents"
      >
        <template #day-body="{ date, week }">
          <div
            v-if="['day', 'week', '4day'].includes(type)"
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          />
        </template>
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark dense>
            <v-btn icon>
              <v-icon x-large> mdi-pencil </v-icon>
            </v-btn>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <v-toolbar-title v-html="selectedEvent.name" />
            <v-spacer />
            <v-btn icon>
              <v-icon x-large> mdi-heart </v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon x-large> mdi-dots-vertical </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details" />
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              {{ $t('calendar.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
import CalendarModeSwitcher from '@/components/calendar/CalendarModeSwitcher'

export default {
  name: 'CalendarViewer',

  components: {
    CalendarModeSwitcher
  },

  props: {
    colours: {
      type: Object,
      required: false,
      default: () => null
    },

    events: {
      type: Array,
      required: true
    },

    header: {
      type: Boolean,
      required: false,
      default: false
    },

    period: {
      type: String,
      required: false,
      default: 'week'
    }
  },

  data: function () {
    return {
      eventColours: null,
      selectedElement: null,
      selectedEvent: {},
      selectedOpen: false,
      ready: false,
      type: 'week',
      focus: '',
      weekday: [0, 1, 2, 3, 4, 5, 6]
    }
  },

  computed: {
    defaultColours() {
      return {
        conference: 'cyan darken-3',
        event: 'teal darken-3',
        meeting: 'blue darken-3',
        travel: 'indigo darken-3',
        birthday: 'orange darken-3',
        holiday: 'green darken-3',
        party: 'amber darken-4',
        pto: 'deep-purple darken-3'
      }
    },

    cal() {
      return this.ready ? this.$refs.calendar : null
    },

    nowY() {
      const top = this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      return top
    },

    overlapMode: function () {
      return this.$store.state.calendarStore.overlapMode
    },

    typeToLabel: function () {
      return {
        'month': this.$t('calendar.month'),
        'week': this.$t('calendar.week'),
        'day': this.$t('calendar.day'),
        '4day': `4 ${this.$t('calendar.days')}`
      }
    }
  },

  watch: {
    type: {
      immediate: false, // initial response via mounted hook
      handler(_newValue, _oldValue) {
        this.scrollToTime()
      }
    }
  },

  created: function () {
    this.eventColours = this.colours || this.defaultColours
    this.type = this.period
  },

  mounted: function () {
    this.$refs.calendar.checkChange()
    this.ready = true
    this.scrollToTime()
    this.updateTimes()
  },

  methods: {
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },

    getEventColour(event) {
      return this.eventColours[event.type] || 'grey darken-1'
    },

    getEvents({ start, end }) {
      this.$emit('update:period', { start, end })
    },

    next() {
      this.$refs.calendar.next()
    },

    prev() {
      this.$refs.calendar.prev()
    },

    scrollToTime() {
      if (['day', 'week', '4day'].includes(this.type)) {
        const time = this.getCurrentTime()
        const first = Math.max(0, time - (time % 30) - 30)

        this.cal.scrollToTime(first)
      }
    },

    setToday() {
      this.focus = ''
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        // eslint-disable-next-line no-return-assign
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    translate(key) {
      return this.$root.$t(key)
    },

    translateEventName(event) {
      return this.$t(`calendar.entry.${event.input.type}`)
    },

    updateTimes() {
      const updater = function () {
        return this.cal.updateTimes()
      }

      window.setInterval(() => updater, 60 * 1000)
    },

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/components/VCalendar/_variables.scss';

// $calendar-event-font-size: 16px;
// $calendar-event-line-height: 24px;

// $calendar-daily-weekday-font-size: 16px;
// $calendar-daily-day-font-size: 16px;
// $calendar-weekly-weekday-font-size: 16px;

// $calendar-weekly-day-label-font-size: 18px;

.v-calendar-daily__intervals-head,
.v-calendar-daily__intervals-body {
  width: 72px !important;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>

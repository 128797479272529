import VueI18n from 'vue-i18n'
import Vuex from 'vuex' // Vue plugin

import { loadMessages } from '@/locales/utils.js'
import defaultCustomMessages from '@/locales/en.json'
import defaultVuetifyMessages from 'vuetify/lib/locale/en.js'

export const getMessages = (locale) => {
  if (locale === 'en') {
    return { ...defaultCustomMessages, $vuetify: defaultVuetifyMessages }
  } else {
    return (async (locale) => await loadMessages(locale))(locale)
  }
}
export class LocalePlugin {
  static install(Vue, { store }) {
    const locale = store.state.i18nStore.locale || 'en'
    const messages = getMessages(locale)

    Vue.use(VueI18n) // defines Vue.prototype.$i18n

    const i18n = new VueI18n({
      locale: locale,
      fallbackLocale: 'en',
      messages: { [locale]: messages }
    })

    // i18n is a VueI8n object
    Vue.prototype.$_i18n = i18n
    Vuex.Store.prototype.$t = i18n.t
  }
}

<template>
  <div
    class="c-stack d-flex flex-column justify-start xalign-center"
    :class="stackClass"
  >
    <AdStackHeader
      v-if="header"
      :multiple="ads.length > 1"
      :rounded="rounded"
      @close="$emit('close')"
    />
    <div
      v-for="(ad, index) in ads"
      :key="ad.id"
      class="c-ad-slot mb-4"
      :class="{ 'c-cta': ad.adItem.cta }"
    >
      <slot name="ad" :ad="ad" :index="index"/>
    </div>
  </div>
</template>

<script>
import AdStackHeader from '@/components/ad/AdStackHeader'

export default {
  name: 'AdStack',

  components: {
    AdStackHeader
  },

  props: {
    ads: {
      type: Array,
      required: false,
      default: () => []
    },

    header: {
      type: Boolean,
      required: false,
      default: true
    },

    rounded: {
      type: Boolean,
      required: false,
      default: false
    },

    stackClass: {
      type: String,
      required: false,
      default: 'c-stack-class'
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isImage(ad) {
      return !!(ad.adItem.image || ad.adItem)
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-ad-slot {
  min-height: 0;
}
.c-ad-slot.c-outline {
  outline: solid 1px;
}

.c-cta {
  cursor: pointer;
}
</style>

<template>
  <SectionPanel
    :colour="colour"
    :title="title"
    :count="contentCount"
    :loading="isContentLoading"
  >
    <template #toolbar>
      <LayoutToolbar
        v-model="contentLayout"
        :dial="false"
        :items="userFavourites"
        :filters="contentFilters"
        :layouts="layouts"
        :sort="contentSortOptionName"
        :sorts="contentExtendedSortOptions"
        @results="contentResultSet = $event"
        @update:layout="updatePreferences({ favouritesLayout: $event })"
        @update:sort="updatePreferences({ favouritesSort: $event })"
      />
    </template>
    <CardGrid
      v-if="contentLayout === 'grid'"
      :items="contentResultSet"
      :loading="isContentLoading"
    >
      <template #card="props">
        <!-- need .prevent to reverse default behaviour which is to ignore these events  -->
        <DraggableNode
          :active="isContentDraggable"
          drag-type="content"
          :identifier="props.item.id"
          @drop="moveFavourite({ from: $event.dragId, after: $event.dropId })"
        >
          <ContentCardSkeleton v-if="isContentLoading" />
          <ContentCard
            v-else
            :item="props.item"
            @view:item="viewItem(props.item)"
            @view:parent="viewParent(props.item.parent.id)"
            @share="$emit('share', props.item)"
          />
        </DraggableNode>
      </template>
    </CardGrid>
    <CardCarousel
      v-else
      class="pa-4"
      title="home.favourites"
      :items="contentResultSet"
      :card-width="300"
    >
      <template #card="props">
        <ContentCardSkeleton v-if="isContentLoading" />
        <ContentCard
          v-else
          :item="props.item"
          @view:item="viewItem(props.item)"
          @view:parent="viewParent(props.item.parent.id)"
          @share="$emit('share', props.item)"
        />
      </template>
    </CardCarousel>
  </SectionPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dummyItems } from '@/components/content/dummyItems'

// content components
import ContentCard from '@/components/content/ContentCard'
import ContentCardSkeleton from '@/components/content/ContentCardSkeleton'

// base components
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import DraggableNode from '@/components/base/DraggableNode'
import LayoutToolbar from '@/components/base/LayoutToolbar'
import SectionPanel from '@/components/base/SectionPanel'

import contentMixin from '@/components/content/contentMixin.js'

export default {
  name: 'ContentPanel',

  components: {
    CardCarousel,
    CardGrid,
    ContentCard,
    ContentCardSkeleton,
    LayoutToolbar,
    DraggableNode,
    SectionPanel
  },

  mixins: [contentMixin],

  props: {
    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['grid', 'tree']
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      isContentLoading: true,
      contentResultSet: dummyItems().slice(0, 4),
      contentCount: 0,
      contentLayout: ''
    }
  },

  computed: {
    ...mapGetters('contentStore', ['libraryItems']),
    ...mapGetters('userStore', ['getPreference']),

    items() {
      return this.libraryItems
    },

    isContentDraggable() {
      // only allow dragging if all favourites are visible (unfiltered or excluded via search)
      // and the sort order is "as is"
      return (
        this.userFavourites.length === this.contentResultSet.length &&
        this.contentSortOptionName === 'sortByAsIs'
      )
    },

    /* layout settings */

    contentFilters() {
      return this.contentMixin_filters
    },

    contentExtendedSortOptions() {
      return [
        ...this.contentMixin_sorts,
        {
          text: this.$t('ui.sortOptions.sortByAsIs'),
          value: 'sortByAsIs'
        }
      ]
    },

    contentSortOptionName() {
      return this.getPreference('favouritesSort')
    },

    /* maintain favourites */

    userFavouritesIds() {
      return this.$store.state.userStore.favourites
    },

    userFavourites() {
      return this.items
        .filter((item) => this.userFavouritesIds.includes(item.id))
        .sort((a, b) => this.userFavouritesIds.indexOf(a.id) - this.userFavouritesIds.indexOf(b.id))
    },

    userFavouritesCount() {
      return this.userFavourites.length
    }
  },

  watch: {
    contentResultSet: {
      immediate: false,
      handler: function (newResultSet, _oldValue) {
        this.contentCount = newResultSet.length
      }
    }
  },

  created: async function () {
    this.contentLayout = this.getPreference('favouritesLayout')
    await this.fetchLibrary({ resync: false })
    this.contentResultSet = this.userFavourites
    this.isContentLoading = false
  },

  methods: {
    ...mapActions('contentStore', ['fetchLibrary']),
    ...mapActions('userStore', ['updatePreferences', 'moveFavourite']),

    addFavourite(items, item) {
      return [...items, item]
    },

    removeFavourite(items, itemId) {
      return items.filter((item) => item.id !== itemId)
    },

    viewItem(item) {
      this.$router.push({
        name: 'iplayer',
        params: { id: item.id }
      })
    },

    viewParent(parentId) {
      const item = this.items?.find((item) => item.id === parentId)
      this.viewItem(item)
    }
  }
}
</script>


<template>
  <div class="c-image-viewer d-flex">
    <slot>
      <v-img
        class="c-img--contained"
        :class="{'c-fullscreen': fullscreen }"
        :src="src"
        contain
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',

  components: {},

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      src: this.content.contentURL
    }
  }
}
</script>

<style lang="css" scoped>
.c-image-viewer {
  max-height: 100%;
}
.c-img--contained {
  height: calc(var(--c-viewport-height) - var(--c-player-bar-height));
  height: calc(100% - var(--c-player-bar-height));
  width: 100%;
  height: 100%;
  max-height: calc(var(--c-viewport-height) - var(--c-player-bar-height));
}

.c-img--contained.c-fullscreen {
  height: 100vh;
  height: -webkit-fill-available;
  width: auto;
}
</style>

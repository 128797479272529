<template>
  <v-btn fab small>
    <v-icon color="accent" large>
      {{ online ? 'mdi-wifi' : 'mdi-wifi-off' }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'AppOnlineStatus',

  components: {},

  data: function () {
    return {}
  },

  computed: {
    online() {
      return this.$store.state.appStore.online
    }
  }
}
</script>

<style scoped lang="scss">
$badge-font-size: 18px;

@import '@/styles/variables.scss';

.c-notification {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  margin: 0 5px 5px;
  color: white;
  background: var(--v-info-base);
  border-left: 5px solid #187fe7;
}

.c-notification.warn {
  background: var(--v-warning-base);
  border-left-color: #f48a06;
}

.c-notification.error {
  background: var(--v-error-base);
  border-left-color: #b82e24;
}

.c-notification.success {
  background: var(--v-success-base);
  border-left-color: #42a85f;
}
</style>

<template>
  <v-card
    elevation="0"
    class="pa-2"
    :class="[center ? 'd-flex justify-center flex-column text-center mx-auto' : 'd-flex justify-left my-auto']"
    rounded
  >
    <v-avatar
      class="c-person-avatar my-auto"
      :class="{'c-clickable': clickable}"
      :color="colour"
      :size="size"
      @click="clickable && $emit('click')"
    >
      <v-img v-if="avatar" :lazy-src="require('@/assets/images/icons/avatar.png')" :src="avatar" />
      <span v-else class="white--text text-h5">
        {{ personInitials }}
      </span>
    </v-avatar>
    <div class="c-name-block d-flex flex-column">
    <v-card-title
      class="c-person-name pa-0 ma-auto"
      :class="center ? 'text-center' : 'ml-2 text-left'"
    >
      {{ personName }}
    </v-card-title>
    <v-card-subtitle
      v-if="suffixes"
      class="c-person-suffixes py-0"
      :class="center ? 'text-center' : 'text-left'"
    >
      {{ personSuffixes }}
    </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
import { getImage } from '@/config/sanityConfig'

export default {
  name: 'PersonAvatar',

  components: {},

  props: {
    person: {
      type: Object,
      required: true
    },

    center: {
      type: Boolean,
      required: false,
      default: false
    },

    clickable: {
      type: Boolean,
      required: false,
      default: false
    },

    colour: {
      type: String,
      required: false,
      default: 'purple'
    },

    size: {
      type: Number,
      required: false,
      default: 72
    },

    suffixes: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    avatar() {
      return this.person.picture
        ? getImage(this.person.picture).width(this.size).height(this.size).url()
        : this.person.avatar
    },

    personInitials() {
      return (this.person.firstName?.[0] || '?') + (this.person.lastName?.[0] || '?')
    },

    personName() {
      return (
        this.person.displayName ||
        (this.person.honourific
          ? `${this.person.honourific} ${this.person.firstName} ${this.person.lastName}`
          : `${this.person.firstName} ${this.person.lastName}`)
      )
    },

    personSuffixes() {
      return this.person.suffixes?.join[', '] || ''
    }
  }
}
</script>

<style lang="css" scoped>
.c-clickable {
  cursor: pointer;
}

.c-person-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

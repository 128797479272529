<template>
  <div class="c-bar__container">
    <v-app-bar
      class="c-bar"
      app
      clipped-left
      clipped-right
      :color="bannerColour"
      dense
      fixed
    >
      <div class="c-left text-left">
        <v-btn
          v-ripple="{ class: 'primary--text' }"
          class="c-btn c-btn--stateless"
          fab
          small
          text
          @click="toggleAside"
        >
          <v-icon x-large>
            {{ aside ? 'mdi-backburger' : 'mdi-forwardburger' }}
          </v-icon>
        </v-btn>
      </div>

      <!--v-spacer /-->

      <v-toolbar-title class="c-title px-1 text-h6 text-center">
        {{ title }}
      </v-toolbar-title>

      <!--v-spacer/-->

      <div class="c-right text-right" :class="{ 'c-full': !isMobile }">
        <template v-if="!isMobile">
          <v-btn
            v-for="button in buttons"
            :key="button.title"
            class="c-btn c-btn--stateless ml-2"
            fab
            small
            text
            @click="button.action"
          >
            <v-icon :class="button.class()" x-large>
              {{ button.icon[button.choice() ? 0 : 1] }}
            </v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-speed-dial v-model="showActions" direction="bottom" transition="slide-y-transition">
            <template #activator>
              <v-btn v-model="showActions" class="c-btn" :color="bannerColour" fab small>
                <v-icon :class="{ 'accent--text': showActions }" x-large>
                  {{ showActions ? 'mdi-close' : 'mdi-dots-vertical' }}
                </v-icon>
              </v-btn>
            </template>
            <v-btn
              v-for="button in buttonsReversed"
              :key="button.title"
              v-ripple="{ class: 'primary--text' }"
              class="c-btn c-btn--stateless c-btn-dial"
              :color="buttonBackgroundColour"
              fab
              @click="button.action"
            >
              <v-icon :class="button.class()" x-large>
                {{ button.icon[button.choice() ? 0 : 1] }}
              </v-icon>
            </v-btn>
          </v-speed-dial>
        </template>
      </div>
    </v-app-bar>
    <ShareModal
      v-if="share"
      v-model="share"
      :entity-id="item.id"
      route-name="player"
      :subject="item.title"
      :body="item.abstract"
    />
  </div>
</template>

<script>
import ShareModal from '@/components/base/ShareModal'
import mobileMixin from '@/mixins/mobileMixin'
import shareMixin from '@/mixins/shareMixin'
import { mapActions } from 'vuex'

export default {
  name: 'PlayerBar',

  components: {
    ShareModal
  },

  mixins: [mobileMixin, shareMixin],

  model: {
    prop: 'aside',
    event: 'toggle:aside'
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    },

    aside: {
      required: true,
      validator: (prop) => typeof prop === 'boolean' || prop === null
    }
  },

  data: function () {
    return {
      isAsideOpen: this.aside === null ? !this.$vuetify.breakpoint.mobile : !!this.aside,
      isDrawMode: false,
      isFavourite: false,
      isFull: false,
      showActions: false,
      share: false
    }
  },

  computed: {
    /* item */

    itemId() {
      return this.item?.id
    },

    title() {
      return this.item?.title || ''
    },

    /* context */

    hasMaxmin() {
      return document.fullscreenEnabled || document.webkitFullscreenEnabled
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    favourite() {
      return this.$store.state.userStore.favourites.indexOf(this.itemId) > -1
    },

    /* colours */

    bannerColour() {
      // return this.isDark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'
      return this.isDark ? 'black' : 'primary'
    },

    buttonBackgroundColour() {
      return this.isDark ? 'rgba(100, 100, 100, 0.8)' : 'rgba(220, 220, 220, 0.8)'
    },

    favColour() {
      return this.isFavourite ? 'red--text' : ''
    },

    /* buttons */

    buttons() {
      return this.buttonData.filter((button) => button.enabled)
    },

    buttonsReversed() {
      return this.buttons.slice().reverse()
    },

    buttonData() {
      return [
        {
          title: 'favourite',
          enabled: true,
          action: this.toggleFavourite,
          choice: () => this.isFavourite,
          class: () => 'red--text',
          icon: ['mdi-heart', 'mdi-heart-outline']
        },
        {
          title: 'maxmin',
          enabled: this.hasMaxmin ? true : false,
          action: this.onMaxMin,
          choice: () => this.isFull,
          class: () => '',
          icon: ['mdi-fullscreen-exit', 'mdi-fullscreen']
        },
        {
          title: 'darkMode',
          enabled: true,
          action: this.toggleDarkMode,
          choice: () => this.isDark,
          class: () => '',
          icon: ['mdi-yin-yang', 'mdi-yin-yang']
        },
        {
          title: 'share',
          enabled: true,
          action: this.shareItem,
          choice: () => true,
          class: () => '',
          icon: ['mdi-share-variant', 'mdi-share-variant']
        },
        {
          title: 'drawMode',
          enabled: this.$feature('drawMode'),
          action: this.toggleDrawMode,
          choice: () => this.isDrawMode,
          class: () => (this.isDrawMode ? 'yellow--text' : ''),
          icon: ['mdi-pencil-off', 'mdi-pencil']
        },
        {
          title: 'close',
          enabled: true,
          action: this.closePlayer,
          choice: () => true,
          class: () => '',
          icon: ['mdi-exit-to-app', 'mdi-exit-to-app']
        }
      ]
    }
  },

  watch: {
    item: {
      immediate: false,
      handler: function (_newItem, _oldItem) {
        this.isFavourite = this.favourite
      }
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  mounted: function () {
    document.addEventListener('fullscreenchange', this.onFullscreenChange)
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange)
  },

  beforeDestroy: function () {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange)
    document.removeEventListener('webkitfullscreenchange', this.onFullscreenChange)
  },

  methods: {
    ...mapActions('themeStore', ['updateMode']),
    ...mapActions('userStore', ['addToFavourites', 'removeFromFavourites']),

    /*
     * manage content aside
     */

    toggleAside() {
      this.isAsideOpen = !this.isAsideOpen
      this.$emit('toggle:aside', this.isAsideOpen)
    },

    /*
     * manage dark mode
     */

    toggleDarkMode() {
      this.updateMode({ isDark: !this.isDark, vm: this })
    },

    /*
     * manage draw mode
     */

    toggleDrawMode() {
      this.isDrawMode = !this.isDrawMode
      this.$emit('activate:canvas', this.isDrawMode)
    },

    /*
     * manage favourites
     */

    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      if (this.isFavourite) {
        this.addToFavourites({ id: this.itemId })
        this.$emit('add:favourite')
      } else {
        this.removeFromFavourites({ id: this.itemId })
        this.$emit('remove:favourite')
      }
    },

    /*
     * manage sharing
     */

    async shareItem() {
      // shareItem returns true if there is no native share modal
      this.share = await this.shareMixin_shareItem(this.item)
    },

    /*
     * manage window
     */

    closePlayer() {
      this.isAsideOpen = false
      if (this.isAsideOpen) this.toggleAside()
      if (this.isDrawMode) this.toggleDrawMode()
      this.$emit('close')
    },

    isFullscreen() {
      /*
        document.fullscreenElement will point to the element that
        is in fullscreen mode (if there is one)

        - IE uses msFullscreenElement
        - older Mozilla uses mozFullScreenElement
        - Safari requires a webkit prefix
      */
      return !!(document.fullscreenElement || document.webkitFullscreenElement)
    },

    isFullscreenVideo() {
      const videoElement = document.fullscreenElement || document.webkitFullscreenElement
      return videoElement && videoElement.nodeName === 'VIDEO'
    },

    onMaxMin() {
      this.isFull ? this.exitFullscreen() : this.requestFullscreen()
    },

    onFullscreenChange() {
      this.$emit('fullscreen', this.isFullscreen())
    },

    requestFullscreen() {
      if (!this.isFullscreen()) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen()
        }
      }
    },

    exitFullscreen() {
      if (this.isFullscreen()) {
        // exitFullscreen is only available on the Document object
        if (document.documentElement.exitFullscreen) {
          document.documentElement.exitFullscreen()
        } else if (document.documentElement.webkitExitFullscreen) {
          document.documentElement.webkitExitFullscreen()
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-bar {
  z-index: 3;
}

.c-bar__buttons {
  height: 100%;
}

.c-left {
  flex: 1 1 0;
}
.c-right {
  flex: 1 1 0;
}
.c-right.c-full {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.c-title {
  flex: auto; /* grows and shrinks */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-favourite {
  color: red;
}

.c-btn-dial {
  background-color: rgba(100, 100, 100, 0.8);
}
</style>

<template>
  <v-card
    class="c-card d-flex flex-column mx-auto"
    justify-self="center"
    height="100%"
  >
    <v-skeleton-loader
      class="c-client-skeleton pb-5"
      elevation="2"
      type="avatar-big, list-item-avatar@4"
      :types="{ 'avatar-big': 'avatar' }"
    />
  </v-card>
</template>

<script>
export default {
  name: 'ContentCardSkeleton',

  components: {},

  data: function () {
    return {
      attrs: {
        elevation: 2
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  max-width: min(500px, 100%);
  border-radius: 16px;
}
</style>

<style lang="css">
.c-client-skeleton .v-skeleton-loader__avatar-big {
  display: flex;
  justify-content: center;
  margin: 24px 0px;
}

.c-client-skeleton .v-skeleton-loader__avatar-big .v-skeleton-loader__avatar {
  height: 100px;
  width: 100px;
}

.c-client-skeleton .v-skeleton-loader__list-item-avatar {
  margin: 16px 0px;
}
</style>

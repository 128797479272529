export default {
  methods: {
    layoutMixin_calculateWindowScrollbarSize() {
      return {
        width: window.innerWidth - document.documentElement.clientWidth,
        height: window.innerHeight - document.documentElement.clientHeight
      }
    },

    layoutMixin_calculateScrollbarSize(element) {
      // console.debug('window.innerWidth=', window.innerWidth)
      // console.debug('document.body.clientWidth=', document.body.clientWidth)
      const el = element || this.$el
      const borderWidth =
        parseInt(getComputedStyle(el).borderLeftWidth, 10) +
        parseInt(getComputedStyle(el).borderRightWidth, 10)
      const borderHeight =
        parseInt(getComputedStyle(el).borderTopWidth, 10) +
        parseInt(getComputedStyle(el).borderBottomWidth, 10)

      // offset dimensions include border and scrollbar (whereas client dimensions do not)
      const scrollbarWidth = el.offsetWidth - el.clientWidth - borderWidth
      const scrollbarHeight = el.offsetHeight - el.clientHeight - borderHeight

      return {
        width: scrollbarWidth,
        height: scrollbarHeight
      }
    }
  }
}

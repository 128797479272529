<template>
  <div class="text-left pl-3">
    <v-chip
      v-for="(tag, i) in tags"
      :key="i"
      class="mr-2"
      label
      color="grey lighten-1"
      text-color="black"
    >
      {{ tag.label }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'PostTags',

  components: {},

  props: {
    tags: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="css"></style>

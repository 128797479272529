import store from '@/store'

export const fetchPosts = async ({ filter = null }) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const queryString = filter ? `?filter=${encodeURIComponent(filter)}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/posts${queryString}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    return []
  }
}

export const fetchPost = async (postId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/post/${postId}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    return {}
  }
}

export const postComment = async (comment) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const postId = comment.postId

  try {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...comment })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/post/${postId}/comment`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    return {
      message: error
    }
  }
}

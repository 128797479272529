import modules from './modules'
import { hydrateState } from '@/store/persistence/stateManager.js'

export default {
  async restoreState({ state, dispatch }, { vm }) {
    // restore the session state; otherwise rely on other store modules to set the state
    console.info('[store]: Restoring module states...')
    dispatch(`appStore/storeState`)

    const restorePromises = Object.keys(modules).map(async (moduleName) => {
      const moduleStorage = state[moduleName].storage
      const moduleState = await hydrateState(moduleName, moduleStorage)
      // console.debug(`[store]: Session state for ${module}=`, moduleState)

      // only update the module store if previous session data exists
      if (Object.keys(moduleState).length > 0) {
        dispatch(`${moduleName}/restoreState`, {
          sessionState: { ...moduleState },
          vm
        })
      }
    })

    await Promise.all(restorePromises)

    console.info('[store]: State restoration actions complete!')
  },

  async reflectOrg({ state, dispatch }, { orgConfig, vm }) {
    const activePortalKey = state.tenantStore.portalKey

    const orgInfo = {
      orgLink: orgConfig?.orgInfo?.orgLink || '',
      orgLogo: orgConfig?.orgInfo?.orgLogo || '/img/logos/default-logo.png',
      orgName: orgConfig?.orgInfo?.orgName || '<Missing>',
      about: orgConfig?.orgInfo?.about || '<Missing>',
      contact: orgConfig?.orgInfo?.contact || '<Missing>',
      disclaimer: orgConfig?.orgInfo?.disclaimer || '<Missing>',
      privacy: orgConfig?.orgInfo?.privacy || '<Missing>',
      tou: orgConfig?.orgInfo?.tou || '<Missing>'
    }

    const indexMenuItem = orgConfig.menuItems.find((menuItem) => menuItem.index)
    const indexRoute = indexMenuItem ? { name: indexMenuItem } : { name: 'home' }

    // set locale (do this first!)
    const locale = orgConfig.locales.includes(state.i18nStore.locale)
      ? state.i18nStore.locale
      : orgConfig.locales[0]
    dispatch('i18nStore/updateLocale', { locale, vm })

    // set portal state
    await dispatch('portalStore/updatePortals', {
      portals: orgConfig.portals || [],
      activePortalKey: activePortalKey
    })

    // set content state
    dispatch('contentStore/updateCollections', { collections: orgConfig.collections })
    dispatch('contentStore/updateTaxonomyTitles', { taxonomyTitles: orgConfig.taxonomyTitles })

    // set menu (note: menu depends on collections! )
    dispatch('menuStore/updateMenu', { menuItems: orgConfig.menuItems })
    dispatch('menuStore/updateIndexRoute', { route: indexRoute })

    // set visual elements
    dispatch('orgStore/updateOrgInfo', { orgInfo })
    dispatch('themeStore/updateOrgTheme', { orgTheme: orgConfig.theme, vm })
  },

  reflectUser({ dispatch }, { user }) {
    dispatch('userStore/updateUser', { user })
    // dispatch('i18nStore/updateLocale', { locale: this.user.locale, vm })
  }
}

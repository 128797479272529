<template>
  <v-dialog
    v-model="showDialog"
    content-class="c-player-modal"
    fullscreen
    transition="modal-fade"
    @keydown.esc="closePlayer"
  >
    <ContentPlayer
      :item="item"
      @close="closePlayer"
      @add:favourite="$emit('add:favourite')"
      @remove:favourite="$emit('remove:favourite')"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

import ContentPlayer from '@/components/content/ContentPlayer'

const modalFade = createSimpleTransition('modal-fade')
Vue.component('ModalFade', modalFade)

export default {
  name: 'ContentModal',

  components: {
    ContentPlayer
  },

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      showDialog: this.show
    }
  },

  computed: {},

  created: function () {
    // register a guard to close the player if the user selects a different menu item
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.closePlayer()
      next(false)
    })

    // destroy the router guard when this component's lifecycle ends
    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  mounted: function () {
    // suspend scrolling while content is being viewed
    document.body.style.top = `-${window.scrollY}px` // capture before setting position to fixed
    document.body.style.position = 'fixed'
  },

  methods: {
    closePlayer() {
      // resume scrolling
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)

      // hide modal
      this.showDialog = false
      this.$emit('close', this.showDialog)
    }
  }
}
</script>

<style lang="css" scoped>
.c-player-modal {
  overscroll-behavior: contain;
  overflow-y: auto; /* enables overscroll setting */
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-leave,
.modal-fade-enter-to {
  opacity: 1;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 5s ease;
}
</style>

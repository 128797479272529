// import client from '@client'
import client from '@/services/real'

class PostService {
  constructor() {
    this.type = 'sanity'
  }

  fetchPosts = async ({ filter }) => {
    const posts = await client.fetchPosts({ filter })
    return posts
  }

  fetchPost = async (id) => {
    const post = await client.fetchPost(id)
    return post.id !== undefined ? post : null
  }

  postComment = async (comment) => {
    const commentId = await client.postComment(comment)
    return commentId
  }
}

export default PostService
export const postService = new PostService()

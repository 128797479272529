// import client from '@client'
import client from '@/services/real'

class OrgService {
  constructor() {
    this.type = ''
  }

  fetchOrgs = async () => {
    const orgs = await client.fetchOrgs()
    return orgs
  }

  fetchOrgPersons = async ({ orgKey, portalKey }) => {
    const orgs = await client.fetchOrgPersons({ orgKey, portalKey })
    return orgs
  }

  fetchOrgConfig = async (orgKey) => {
    const orgConfig = await client.fetchOrgConfig(orgKey)
    return orgConfig.key !== undefined ? orgConfig : null
  }
}

export default OrgService
export const orgService = new OrgService()

import { render, staticRenderFns } from "./ModelControls.vue?vue&type=template&id=6e4e16af&scoped=true&"
import script from "./ModelControls.vue?vue&type=script&lang=js&"
export * from "./ModelControls.vue?vue&type=script&lang=js&"
import style0 from "./ModelControls.vue?vue&type=style&index=0&id=6e4e16af&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e4e16af",
  null
  
)

export default component.exports
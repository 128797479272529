<template>
  <div class="c-audio-player" :style="`background-image: url('${poster}')`">
    <audio controls>
      <source :src="url" type="audio/mpeg">
      <track
        v-for="track in tracks"
        :key="track.kind + '.' + track.locale"
        :src="track.url"
        :kind="track.kind"
        :srclang="track.locale"
        :label="locales[track.locale].name"
      >
      {{ $t('notice.browser.audioNotSupported') }}
    </audio>
  </div>
</template>

<script>
import locales from '@/locales'
import { getURL } from '@/config/sanityConfig'

/* end of FIXME */

export default {
  name: 'AudioBlock',

  props: {
    image: {
      type: Object,
      required: false,
      default: () => null
    },

    tracks: {
      type: Array,
      required: false,
      default: () => {}
    },

    url: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    locales() {
      return locales
    },

    poster() {
      const defaultImage = '/img/backgrounds/podcast-background.jpg'
      return this.image ? getURL(this.image, defaultImage) : defaultImage
    }
  }
}
</script>

<style lang="css" scoped>
.c-audio-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  position: relative;
  border-color: var(--v-accent-base);
  border-style: solid;
  margin: 0px auto 24px auto;
}

.c-audio-player audio {
  background-color: rgba(20, 20, 20, 0.2);
  position: absolute;
  bottom: 0px;
  width: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>

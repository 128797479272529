<template>
  <div class="c-ad-header d-flex align-stretch justify-space-between">
    <div class="c-spacer pl-5" />
    <div class="c-title my-auto text-caption text-uppercase">
      {{ multiple ? $t('ui.advertisements') : $t('ui.advertisement') }}
    </div>
    <div class="c-close">
      <v-btn
        class="c-btn-close"
        :class="{ 'c-rounded': rounded }"
        :color="isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'"
        block
        ripple
        x-small
        @click="$emit('close')"
      >
        <v-icon small> mdi-close </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdStackHeader',

  components: {},

  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    rounded: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-close {
  padding: 0 !important;
  min-width: 24px !important;
}
.c-btn-close.c-rounded {
  border-top-right-radius: 4px;
}
.c-btn-close i:hover {
  transform: scale(1.25);
}
</style>

import { render, staticRenderFns } from "./CardGrid.vue?vue&type=template&id=dd699dc0&scoped=true&"
import script from "./CardGrid.vue?vue&type=script&lang=js&"
export * from "./CardGrid.vue?vue&type=script&lang=js&"
import style0 from "./CardGrid.vue?vue&type=style&index=0&id=dd699dc0&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd699dc0",
  null
  
)

export default component.exports
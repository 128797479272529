import orgs from './orgs.json'
import { fakeFetch } from '@/services/mock/fakeFetch'

export const fetchOrgs = () => {
  return fakeFetch(orgs, 500) // wait 500ms before returning
}

export const fetchOrgConfig = (key) => {
  const config = orgs.find((org) => org.key === key || org.aliases.includes(key))
  if (!config) throw new Error(`Cannot find org with key=${key}.`)
  return fakeFetch(config, 500) // wait 500ms before returning
}

import WebService from '@/services/webService'

export default {
  data: function () {
    return {
      webService: null
    }
  },

  created: function () {
    this.webService = new WebService()
  },

  mounted: function () {
    window.addEventListener('message', this.onMessage)

    const iframeWindow = this.$refs.iframe.contentWindow

    this.$bus.$on('select:guidance', (guidance) => {
      const message = this.webService.generateMessage('guidance', guidance)
      const targetOrigin = this.webService.extractOrigin(this.src)
      const targetWindow = iframeWindow
      // inform the current page that guidance was turned on
      this.webService.sendMessage(targetWindow, message, targetOrigin)
      // update context for future handshakes with new pages inside iframe
      this.webService.setContext({ guidance })
    })
  },

  beforeDestroy: function () {
    window.removeEventListener('message', this.onMessage)
  },

  methods: {
    // receive messages from iframe document
    onMessage(e) {
      this.webService.onMessage(e, this.src)
    }
  }
}

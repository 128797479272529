<template>
  <div class="c-video-player">
    <video
      class="c-video"
      controlsList="nodownload noremoteplayback"
      controls
      :poster="poster"
    >
      <source :src="url" type="video/mp4">
      <track
        v-for="track in tracks"
        :key="track.kind + '.' + track.locale"
        :src="track.url"
        :kind="track.kind"
        :srclang="track.locale"
        :label="locales[track.locale].name"
      >
      {{ $t('notice.browser.videoNotSupported') }}
    </video>
  </div>
</template>

<script>
import locales from '@/locales'
import { getURL } from '@/config/sanityConfig'

export default {
  name: 'VideoBlock',

  props: {
    image: {
      type: Object,
      required: false,
      default: () => null
    },

    tracks: {
      type: Array,
      required: false,
      default: () => {}
    },

    url: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    locales() {
      return locales
    },

    poster() {
      // const defaultImage = '/img/backgrounds/video-background.png'
      return getURL(this.image)
    }
  }
}
</script>

<style lang="css" scoped>
.c-video-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  position: relative;
  border-color: var(--v-accent-base);
  border-style: solid;
  margin: 0px auto 24px auto;
}
.c-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

import PostService from '@/services/postService'

const postService = new PostService()

export default {
  /* common actions */

  restoreState({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */

  async fetchPosts({ state, commit, getters }, { resync = false }) {
    if (resync || state.posts.length < 1) {
      try {
        let promise
        if (state.isLoading && state.promise) {
          console.debug('[postStore]: Using existing promise.')
          promise = state.promise
        } else {
          const filter = getters.activePortal?.postFilter
          promise = postService.fetchPosts({ filter })
          commit('setLoading', { loading: true, promise })
        }
        console.debug(`[postStore]: Fetching posts from CMS.`)
        const posts = await promise
        commit('setPosts', posts)
        commit('setLoading', { loading: false })
      } catch (error) {
        console.warn('[postStore]: Using cached posts.', error)
      }
    } else {
      console.debug(`[postStore]: Fetching posts from cache.`)
    }
    return state.posts
  },

  async fetchPost({ state, commit, getters }, { resync = false }) {
    if (resync || state.posts.length < 1) {
      try {
        let promise
        if (state.isLoading && state.promise) {
          console.debug('[postStore]: Using existing promise.')
          promise = state.promise
        } else {
          const filter = getters.activePortal?.postFilter
          promise = postService.fetchPosts({ filter })
          commit('setLoading', { loading: true, promise })
        }
        console.debug(`[postStore]: Fetching posts from CMS.`)
        const posts = await promise
        commit('setPosts', posts)
        commit('setLoading', { loading: false })
      } catch (error) {
        console.error('[postStore]:', error)
      }
    } else {
      console.debug(`[postStore]: Fetching posts from cache.`)
    }
    return state.posts
  },

  postComment({ commit }, comment) {
    postService.postComment(comment)
    commit('postComment', comment)
  }
}

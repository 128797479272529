<template>
  <v-toolbar dense floating rounded="pill">
    <!-- zoom controls -->
    <v-btn-toggle v-if="!isReallyMobile" color="primary" rounded>
      <v-btn
        v-tooltip="$t('model.controls.zoomIn')"
        class="c-btn c-btn--stateless"
        :disabled="!zoomInActive"
        fab
        small
        text
        @click.stop="zoomIn"
      >
        <v-icon large> mdi-plus </v-icon>
      </v-btn>
      <v-btn
        v-tooltip="$t('model.controls.zoomOut')"
        class="c-btn c-btn--stateless"
        :disabled="!zoomOutActive"
        fab
        small
        text
        @click.stop="zoomOut"
      >
        <v-icon large> mdi-minus </v-icon>
      </v-btn>
    </v-btn-toggle>

    <!-- interactive modes -->
    <v-btn-toggle v-model="modeIndex" class="mx-2" color="primary" mandatory rounded>
      <v-btn
        v-tooltip="help ? $t('model.help.move') : $t('model.controls.move')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isMovable"
        small
        text
        @click.stop="toggleMoveMode"
      >
        <v-icon large> mdi-cursor-move </v-icon>
      </v-btn>
      <v-btn
        v-tooltip="help ? $t('model.help.rotate') : $t('model.controls.rotate')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isRotatable"
        small
        text
        @click.stop="toggleRotateMode"
      >
        <v-icon large> mdi-sync </v-icon>
      </v-btn>
      <v-btn
        v-tooltip="help ? $t('model.help.zoom') : $t('model.controls.zoom')"
        class="c-btn"
        active-class="c-btn--active"
        fab
        :input-value="isScalable"
        small
        text
        @click.stop="toggleScaleMode"
      >
        <v-icon large> mdi-arrow-expand </v-icon>
      </v-btn>
    </v-btn-toggle>

    <!-- reset button -->
    <v-btn
      v-tooltip="$t('model.controls.reset')"
      class="c-btn c-btn--stateless"
      fab
      small
      text
      @click.stop="reset"
    >
      <v-icon large> mdi-crosshairs-gps </v-icon>
    </v-btn>

    <!-- model augmentations -->
    <v-btn
      v-tooltip="$t('model.controls.revolve')"
      class="c-btn"
      active-class="c-btn--active"
      fab
      :input-value="isRevolving"
      small
      text
      @click.stop="toggleRevolution"
    >
      <v-icon large> mdi-fan </v-icon>
    </v-btn>
    <v-btn
      v-if="hasAnnotations"
      v-tooltip="$t('model.controls.annotations')"
      class="c-btn c-btn--stateful"
      active-class="c-btn--active"
      depressed
      fab
      :input-value="showAnnotations"
      small
      text
      @click.stop="toggleAnnotations"
    >
      <v-icon large> mdi-tag </v-icon>
    </v-btn>

    <!-- scenes -->
    <SceneSelector
      v-if="hasMoreThanOneScene"
      v-model="selectedSceneId"
      :scenes="scenes"
      @select="selectScene($event)"
    />
    <AnimationSelector
      v-if="hasAnimations"
      :animations="animations"
      @select="selectAnimation($event)"
    />
    <v-btn
      v-if="description"
      v-tooltip="$t('model.controls.description')"
      class="c-btn"
      active-class="c-btn--active"
      fab
      :input-value="showInfo"
      small
      text
      @click.stop="toggleInfo"
    >
      <v-icon large> mdi-information-variant-circle-outline </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { EventNames } from '../constants/ControlConstants'
import AnimationSelector from './AnimationSelector'
import SceneSelector from './SceneSelector'
import mobileMixin from '@/mixins/mobileMixin.js'

const MIN_ZOOM = -5
const MAX_ZOOM = +5

const MODE_MOVE = 'move'
const MODE_ROTATE = 'rotate'
const MODE_SCALE = 'scale'

export default {
  name: 'ModelControls',

  components: {
    AnimationSelector,
    SceneSelector
  },

  mixins: [mobileMixin],

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    rerender: {
      type: Number,
      required: false,
      default: 0
    },

    scenes: {
      type: Array,
      required: false,
      default: () => []
    },

    help: {
      type: Boolean,
      required: false,
      default: true
    },

    initialSceneId: {
      type: String,
      required: false,
      default: ''
    },

    zeroZoom: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // scene
      selectedSceneId: this.initialSceneId,
      // zoom settings
      size: 0,
      // mode settings
      mode: MODE_ROTATE, // "move", "rotate" or "scale"
      modeIndex: 1,
      isMovable: false,
      isRotatable: true,
      isScalable: false,
      // augmentation settings
      isRevolving: false,
      showAnnotations: true,
      // info
      showInfo: false
    }
  },

  computed: {
    /* scene context */

    animations() {
      return this.selectedScene?.[this.locale].animations || []
    },

    hasAnimations() {
      return this.animations.length > 0
    },

    hasMoreThanOneScene() {
      return this.scenes.length > 1
    },

    selectedScene() {
      return this.scenes.find((scene) => scene.id === this.selectedSceneId)
    },

    /* information context */

    description() {
      return this.selectedScene?.[this.locale].description || ''
    },

    hasAnnotations() {
      return !!this.selectedScene?.[this.locale].annotations
    },

    /* zoom context */

    maxZoom() {
      return this.zeroZoom ? MAX_ZOOM * 2 : MAX_ZOOM
    },

    minZoom() {
      return this.zeroZoom ? 0 : MIN_ZOOM
    },

    zoomInActive() {
      return this.size < this.maxZoom
    },

    zoomOutActive() {
      return this.size > this.minZoom
    },

    /* application context */

    locale() {
      return this.$store.state.i18nStore.locale
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    }
  },

  watch: {
    rerender: {
      handler(newValue, _oldValue) {
        if (newValue) {
          this.resetControls()
        }
      }
    },

    show: {
      immediate: true,
      handler(newShow, _oldShow) {
        this.showInfo = newShow
      }
    }
  },

  methods: {
    // zoom controls
    zoomIn() {
      if (this.size < this.maxZoom) {
        this.size = this.size < this.maxZoom ? ++this.size : this.size

        this.raiseEvent(EventNames.ZOOM_IN, this.size)
      }
    },

    zoomOut() {
      if (this.size > this.minZoom) {
        this.size = this.size > this.minZoom ? --this.size : this.size

        this.raiseEvent(EventNames.ZOOM_OUT, this.size)
      }
    },

    // interactive modes
    toggleMoveMode() {
      if (this.mode !== MODE_MOVE) {
        this.toggleModeButtons(MODE_MOVE)
        this.mode = MODE_MOVE
        this.raiseEvent(EventNames.MODE_MOVE, this.isMovable ? 1 : 0)
      }
    },

    toggleRotateMode() {
      if (this.mode !== MODE_ROTATE) {
        this.toggleModeButtons(MODE_ROTATE)
        this.mode = MODE_ROTATE
        this.raiseEvent(EventNames.MODE_ROTATE, this.isRotatable ? 1 : 0)
      }
    },

    toggleScaleMode() {
      if (this.mode !== MODE_SCALE) {
        this.toggleModeButtons(MODE_SCALE)
        this.mode = MODE_SCALE
        this.raiseEvent(EventNames.MODE_SCALE, this.isScalable ? 1 : 0)
      }
    },

    toggleModeButtons(newMode) {
      if (newMode === MODE_MOVE) {
        this.isMovable = true
        this.isRotatable = false
        this.isScalable = false
      }

      if (newMode === MODE_ROTATE) {
        this.isMovable = false
        this.isRotatable = true
        this.isScalable = false
      }

      if (newMode === MODE_SCALE) {
        this.isMovable = false
        this.isRotatable = false
        this.isScalable = true
      }

      this.$emit('mode', newMode)
    },

    resetControls() {
      // reset zoom
      this.size = 0
      // reset mode
      this.mode = MODE_ROTATE
      this.modeIndex = 1
      this.isMovable = false
      this.isRotatable = true
      this.isScalable = false
      // reset automations / augmentations
      this.isRevolving = false
      this.showAnnotations = false
    },

    resetModel() {
      this.raiseEvent(EventNames.RESET, 0)
    },

    reset() {
      this.resetModel()
      this.resetControls()
    },

    // automations
    toggleRevolution() {
      this.isRevolving = !this.isRevolving
      this.raiseEvent(EventNames.REVOLVE, this.isRevolving ? 1 : 0)
    },

    // augmentations: annotations and animations
    toggleAnnotations() {
      this.showAnnotations = !this.showAnnotations
      this.raiseEvent(EventNames.SHOW_ANNOTATIONS, '*')
    },

    selectAnimation(animationId) {
      this.raiseEvent(EventNames.TOGGLE_ANIMATION, animationId)
    },

    selectScene(sceneId) {
      if (this.showInfo) this.$emit('info', this.description)
      this.raiseEvent(EventNames.LOAD_SCENE, sceneId)
    },

    // info
    toggleInfo() {
      this.showInfo = !this.showInfo
      if (this.showInfo) this.$emit('info', this.description)
      this.$emit('update:show', this.showInfo)
    },

    raiseEvent(eventName, param) {
      const message = { eventName, param }
      this.$emit('message', message)
    }
  }
}
</script>

<style lang="css" scoped>
.c-selected-item {
  background-color: var(--v-accent-base);
}
.v-snack__action {
  align-self: start;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

import menuConfig from '@/config/menuConfig.json'
import orgConfig from '@/config/defaultOrgConfig.json'

Vue.use(Vuex)

const COLLECTION_MENU_KEY = 'collections'

const initialState = {
  indexRoute: {},
  menuItems: [],
  menuRoutes: []
}

const orgState = () => {
  const menuItems = []
  orgConfig.menuItems.forEach((orgMenuItem) => {
    const configMenuItem = menuConfig.find((menuItem) => menuItem.key === orgMenuItem.keyword)
    menuItems.push({
      ...configMenuItem,
      translated: false
    })
  })

  return {
    indexRoute: { name: 'home' },
    menuItems: menuItems,
    menuRoutes: menuItems.map((menuItem) => menuItem.route)
  }
}

export default {
  namespaced: true,

  state: () => ({ ...initialState, ...orgState() }),

  getters: {
    getMenuItemTitle(state) {
      return (route, locale) => {
        const item = state.menuItems.find((item) => {
          return route.name === 'collection'
            ? route.params?.keyword === item.to?.params?.keyword
            : route.name === (item.to?.name || item.route)
        })

        return item
          ? item.translated
            ? item.title[locale] || item.title.en
            : Vuex.Store.prototype.$t(item.title)
          : '<Untitled>'
      }
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setMenuItems(state, menuItems) {
      state.menuItems = menuItems
    },

    setMenuRoutes(state, menuRoutes) {
      state.menuRoutes = menuRoutes
    },

    setIndexRoute(state, indexRoute) {
      state.indexRoute = indexRoute
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    updateMenu({ commit, rootState }, { menuItems: orgMenuItems }) {
      // construct the new menu by extracting menu items from the set in menuConfig
      const menuItems = []
      orgMenuItems.forEach((orgMenuItem) => {
        const configMenuItem = menuConfig.find((menuItem) => menuItem.key === orgMenuItem.keyword)
        const updatedMenuItem = {
          ...configMenuItem,
          icon: orgMenuItem.customIcon || configMenuItem.icon,

          title: orgMenuItem.title || configMenuItem.title,
          translated: !!orgMenuItem.title,

          // handle custom routes (web or link)
          to:
            orgMenuItem.keyword === 'link'
              ? {
                  name: configMenuItem.route,
                  path: orgMenuItem.customAttribute
                }
              : orgMenuItem.keyword === 'web'
              ? {
                  name: configMenuItem.route,
                  query: {
                    href: orgMenuItem.customAttribute
                  }
                }
              : ''
        }
        menuItems.push(updatedMenuItem)
      })

      // update menu routes
      const menuRoutes = menuItems.map((menuItem) => menuItem.route)
      commit('setMenuRoutes', menuRoutes)

      // insert collections
      const index = menuItems.findIndex((menuItem) => menuItem.key === COLLECTION_MENU_KEY)

      if (index >= 0) {
        // split the menu keys into two groups
        const preMenuItems = menuItems.slice(0, index)
        const postMenuItems = menuItems.slice(index + 1)

        // dynamically construct a menu item for each collection
        const collections = rootState.contentStore.collections
        const collectionMenuItems = collections.map((collection) => {
          return {
            key: `collection:${collection.key}`,
            // icon: `<font-awesome-icon icon=${collection.icon}` />
            icon: collection.icon,
            colour: collection.colour || 'primary--text',
            title: collection.title,
            route: 'collection',
            priority: true,
            base: true,
            // only used for collections
            to: { name: 'collection', params: { keyword: `${collection.key}` } },
            translated: true
          }
        })

        // commit a dynamically constructed menu (includes collections)
        commit('setMenuItems', [...preMenuItems, ...collectionMenuItems, ...postMenuItems])
      } else {
        // commit the menu items (with no collections)
        commit('setMenuItems', [...menuItems])
      }
    },

    updateIndexRoute({ commit }, { route }) {
      commit('setIndexRoute', route)
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import { tenantService } from '@/services/tenantService'

Vue.use(Vuex)

const HOSTNAME = window.location.hostname.toLowerCase().replace(/www./g, '')

// parse the URL to get the tenantKey, portalKey and orgKey values
const parseURL = () => {
  const host = HOSTNAME

  // host = host.indexOf(':') > 0 ? host.split(':')[0] : host

  const hostSplit = host.split('.') // convert host into an array of strings
  const hostSplitReversed = hostSplit.slice().reverse()
  const hostParts = hostSplit.length

  const isHosted = (host) => {
    return (
      host.endsWith('lumensify.com') ||
      host.endsWith('medrealms.com') ||
      host.endsWith('netlify.app') ||
      host.endsWith('azurewebsites.net')
    )
  }

  // parse out the tenantKey
  // (tenant key is defined by the second last string in the host's domain)
  const tenantKey =
    hostParts < 2
      ? hostSplitReversed[0]
      : isHosted(host)
      ? hostSplitReversed[2]
      : hostSplitReversed[1]

  // parse out the orgKey
  // (URL is parsed as 'portal.org' (where portal is a single string with a default value of 'app')
  const portalKey =
    (hostParts <= 2 && !isHosted(host)) || (hostParts <= 3 && isHosted(host))
      ? 'app'
      : hostSplit.shift()

  // parse out the orgKey
  const orgKey = hostSplit.join('.')

  // alternate keys for localhost debugging
  const altTenantKey = process.env.VUE_APP_TENANT_KEY || 'localhost'
  const altOrgKey = process.env.VUE_APP_ORG_KEY || 'localhost'
  const altPortalKey = process.env.VUE_APP_PORTAL_KEY || 'app'

  const result = {
    tenantKey: host === 'localhost' ? altTenantKey : tenantKey,
    orgKey: host === 'localhost' ? altOrgKey : orgKey,
    portalKey: host === 'localhost' ? altPortalKey : portalKey
  }

  return result
}

const displayState = ({ tenantKey, orgKey, portalKey }) => {
  const host = HOSTNAME

  if (host === 'localhost' && tenantKey !== 'localhost') {
    console.warn('[tenantStore]: Using "localhost" env overrides...')
  }

  console.info('[tenantStore]: tenantKey=', tenantKey)
  console.info('[tenantStore]: orgKey=', orgKey)
  console.info('[tenantStore]: portalKey=', portalKey)
}

const initialState = {
  tenantKey: '',
  orgKey: '',
  portalKey: '',
  tenant: {},
  loading: false,
  promise: null
}

export default {
  namespaced: true,

  state: () => {
    const { tenantKey, orgKey, portalKey } = parseURL()
    return { ...initialState, tenantKey, orgKey, portalKey }
  },

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // only update tenant object
      state.tenant = newState.tenant
    },

    /* specific mutations */

    setTenantKey(state, tenantKey) {
      state.tenantKey = tenantKey.toLowerCase()
    },

    setPortalKey(state, portalKey) {
      console.warn('[tenantStore]: setPortalKey portalKey=', portalKey)
      state.portalKey = portalKey.toLowerCase()
    },

    setOrgKey(state, orgKey) {
      state.orgKey = orgKey.toLowerCase()
    },

    setAuthorizedDomains(state, authorizedDomains) {
      const lowercaseDomains = authorizedDomains.map((domain) => domain.toLowerCase())
      state.authorizedDomains = lowercaseDomains || []
    },

    setTenant(state, tenant) {
      state.tenant = { ...tenant }
    },

    setLoading(state, { loading, promise = null }) {
      state.loading = loading
      state.promise = promise
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      const mergedState = {
        ...state,
        ...sessionState,
        tenantKey: state.tenantKey,
        orgKey: state.orgKey,
        portalKey: state.portalKey
      }
      commit('syncState', mergedState)

      displayState(state)
    },

    /* specific actions */

    configureTenant({ commit }, { tenantKey, orgKey, portalKey }) {
      // configure elements extracted from URL
      commit('setTenantKey', tenantKey)
      commit('setOrgKey', orgKey)
      commit('setPortalKey', portalKey)
    },

    async fetchTenant({ state, commit }, { resync }) {
      // return cached tenant data
      if (!resync) {
        if (state.tenantKey !== state.tenant.key) {
          throw new Error(
            `[tenantStore]: Cached tenant (${state.tenant.key}) mismatch with url (${state.tenantKey}).`
          )
        }
        return state.tenant
      }

      // fetch tenant data
      try {
        // handle case where multiple components fetch tenant
        let promise
        if (state.loading) {
          console.debug(`[tenantStore]: Using existing promise to fetch tenant.`)
          promise = state.promise
        } else {
          console.debug(`[tenantStore]: Creating new promise to fetch tenant.`)
          promise = tenantService.fetchTenant(state.tenantKey)
          commit('setLoading', { loading: true, promise })
        }
        const tenant = await promise
        commit('setTenant', tenant)
        return tenant
      } finally {
        commit('setLoading', { loading: false })
      }
    },

    updateTenant({ state, commit }, { tenant }) {
      // configure elements from tenant database
      if (state.tenantKey === tenant.tenantKey) {
        commit('setAuthorizedDomains', [...tenant.authorizedDomains, tenant.domain])
      }
    }
  }
}

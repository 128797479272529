<template>
  <div ref="container" class="c-container" :class="{'c-resizable': resize}">
    <div class="c-header">
      <slot name="header" :start="onStartDrag">
        <div class="c-toolbar">
          <v-icon
            class="c-dragger"
            @mousedown.native="onStartDrag($event)"
          >
            mdi-drag
          </v-icon>
        </div>
      </slot>
    </div>

    <div class="c-body">
      <slot name="default">
        <v-card class="c-card">
          Content missing!
        </v-card>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DraggableDiv',

  props: {
    top: {
      type: Number,
      required: false,
      default: 0
    },

    bottom: {
      type: Number,
      required: false,
      default: 0
    },

    left: {
      type: Number,
      required: false,
      default: 0
    },

    right: {
      type: Number,
      required: false,
      default: 0
    },

    resize: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
      headerHeight: 0,
      containerWidth: 0
    }
  },

  mounted: function () {
    // place div at specified top-left position
    const el = this.$refs.container
    el.style.top = this.top + 'px'
    el.style.left = this.left + 'px'

    // set constant dimensions
    // this.headerHeight = this.$slots.header[0].elm.clientHeight
    this.headerHeight = 28
    this.grabberWidth = 36
    this.containerWidth = this.$refs.container.offsetWidth
  },

  methods: {
    onStartDrag(event) {
      event.preventDefault()
      // get the mouse cursor position
      this.clientX = event.clientX
      this.clientY = event.clientY

      // set drag handlers
      document.onmousemove = this.onDrag
      document.onmouseup = this.onStopDrag

      document.ontouchmove = this.onDrag
      document.ontouchend = this.onStopDrag
    },

    onDrag(event) {
      event.preventDefault()

      // capture current position values / deltas
      this.movementX = this.clientX - event.clientX
      this.movementY = this.clientY - event.clientY
      this.clientX = event.clientX
      this.clientY = event.clientY

      // determine min and max position values
      const el = this.$refs.container
      const maxTop = el.parentElement.offsetHeight - this.headerHeight
      const maxLeft = window.innerWidth - this.grabberWidth
      let top = el.offsetTop - this.movementY
      let left = el.offsetLeft - this.movementX
      if (top < 0) top = 0
      if (left < 0) left = 0
      if (top > maxTop) top = maxTop
      if (left > maxLeft) left = maxLeft

      // set the element's new position
      el.style.top = top + 'px'
      el.style.left = left + 'px'
    },

    onStopDrag() {
      document.onmouseup = null
      document.onmousemove = null
      document.ontouchmove = null
      document.ontouchend = null
    }
  }
}
</script>

<style lang="css" scoped>
.c-container {
  position: absolute;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.c-resizable {
  resize: both;
  overflow: auto;
}

.c-header {
  flex-grow: 0;
  z-index: 10;
}
.c-toolbar {
  display: block;
  height: 20px;
  background-color: darkblue;
}

.c-body {
  flex-grow: 1;
}
.c-body .c-card {
  height: 100%;
}
</style>

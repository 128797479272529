export default {
  /* common mutations */

  syncState(state, newState) {
    state.channels = [...newState.channels] || []
  },

  /* specific mutations */

  setActiveChannel: (state, channel) => {
    state.activeChannel = channel
  },

  setChannels: (state, channels) => {
    state.channels = [...channels]
  },

  setLoading: (state, loading) => {
    state.loading = loading
  }
}
